import { IconUserHeart } from '@tabler/icons-react';
import { IActivity, useLazyGetFollowingsActivitiesQuery } from '../../../services/api/social';
import noProfileImg from '../../../assets/no-profile-picture.svg';
import DOMPurify from 'dompurify';

import noActivity from '/src/assets/emptyStates/noActivity.webp';
import { useEffect, useState } from 'react';
import { isCustomErrorResponse } from '../../../utils/errorUtils';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

export default function FriendActivities() {
	const [getFollowingsActivitiesRequest] = useLazyGetFollowingsActivitiesQuery();
	const [activities, setActivities] = useState<IActivity[]>();
	const [isActivityLoading, setIsActivityLoading] = useState(false);

	useEffect(() => {
		const fetchFriendActivities = async () => {
			setIsActivityLoading(true);
			try {
				const response = await getFollowingsActivitiesRequest({ page: 1, limit: 10 }).unwrap();
				if (response) {
					setActivities(response.data);
				}
			} catch (error) {
				if (isCustomErrorResponse(error)) {
					toast.error(error.data.message);
				} else if (error instanceof Error) {
					toast.error(error.message);
				} else {
					toast.error('An unknown error occurred. Please refresh the page.');
				}
			} finally {
				setIsActivityLoading(false);
			}
		};

		fetchFriendActivities();
	}, []);

	return (
		<div className="p-4 min-h-72 bg-lt-lightCream dark:bg-lt-dark-primary-bg border-2 border-lt-beige dark:border-lt-dark-secondary-border rounded-default w-10/12">
			<div className="flex">
				<IconUserHeart />
				<h2 className="font-bold text-lg ml-2">Friend Activities</h2>
			</div>
			{isActivityLoading ? (
				<div className="w-full flex justify-center h-40">
					<span className="loading loading-spinner loading-lg text-center"></span>
				</div>
			) : (
				<div className="mt-4">
					{activities && activities?.length > 0 ? (
						<div className="flex flex-col gap-2">
							{activities?.map((activity: IActivity, index) => (
								<div
									key={activity._id}
									className={`flex items-center ${index !== 0 ? 'border-t border-lt-beige dark:border-lt-dark-popupBorder dark:bg-lt-dark-primary-bg' : ''} pt-2`}
								>
									<img
										src={activity.user.profileImgUrl ?? noProfileImg}
										className="w-10 h-10 rounded-full object-cover"
										alt={activity.user.username}
									/>
									<div className="ml-2 flex flex-col">
										<Link
											to={`/dashboard/profile/${activity.user._id}`}
											className="font-bold text-base hover:text-lt-beige"
										>
											{activity.user.username}
										</Link>
										<span
											dangerouslySetInnerHTML={{
												__html: DOMPurify.sanitize(activity.detail)
											}}
											className="text-sm"
										></span>
									</div>
								</div>
							))}
						</div>
					) : (
						<div className="w-full h-full flex flex-col gap-6 justify-center items-center">
							<img className="w-[250px] h-[260px]" src={noActivity} alt="No friend activity" />
							<span>No friend activity found.</span>
						</div>
					)}
				</div>
			)}
		</div>
	);
}
