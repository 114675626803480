import { Helmet } from 'react-helmet';
import FAQ from './components/FAQ';
import Features from './components/Features';
import Footer from './components/Footer';
import Main from './components/Main';
import Navbar from './components/Navbar';
import Pricing from './components/Pricing';

export default function Home() {
	return (
		<main className="flex flex-col w-full items-center">
			<Helmet>
				<title>Life Tracker - A simple way to track your daily life</title>
				<meta
					name="description"
					content="With Life Tracker, keep track of your daily life, decorate your journal with stickers, share with friends!"
				/>
			</Helmet>
			<div className="flex flex-col items-center max-w-[2000px]s">
				<Navbar />
				<Main />
				<Features />
				<FAQ />
				<Pricing />
				<Footer />
			</div>
		</main>
	);
}
