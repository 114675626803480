import { Link } from 'react-router-dom';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, useAnimations, useGLTF } from '@react-three/drei';
import { Suspense, useState, useEffect, useRef, useCallback } from 'react';
import * as THREE from 'three';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import './index.css';
import { uniqueNamesGenerator, names } from 'unique-names-generator';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';

const loadGSAP = async () => {
	const gsapModule = await import('gsap');
	const TextPlugin = await import('gsap/TextPlugin');
	gsapModule.gsap.registerPlugin(TextPlugin);
	return gsapModule.gsap;
};

function Model({ currentAnimation, playReverse }: { currentAnimation: string; playReverse: boolean }) {
	const { scene, animations } = useGLTF('/bookAnimation.glb');
	if (!scene || !animations) {
		document.getElementById('loading-screen')?.classList.add('hidden');
	}
	const { actions } = useAnimations(animations, scene);
	const [letterMeshes, setLetterMeshes] = useState<THREE.Mesh[]>([]);

	const generateAndAnimateName = useCallback(async () => {
		const gsap = await loadGSAP();
		const randomName = uniqueNamesGenerator({ dictionaries: [names], length: 1 });
		const textMesh = scene.getObjectByName('Text');

		// Eski mesh'leri temizle
		letterMeshes.forEach((mesh) => textMesh?.remove(mesh));
		setLetterMeshes([]);

		// Yeni mesh'leri oluştur
		const loader = new FontLoader();
		loader.load('https://threejs.org/examples/fonts/helvetiker_regular.typeface.json', (font) => {
			const letters = randomName.split('');
			const newLetterMeshes: THREE.Mesh[] = [];
			let offsetX = 0;

			letters.forEach((letter, _index) => {
				const letterGeometry = new TextGeometry(letter, {
					font: font,
					size: 0.3,
					depth: 0.01
				});

				const letterMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });
				const letterMesh = new THREE.Mesh(letterGeometry, letterMaterial);

				letterMesh.position.set(offsetX, 0, 0);
				letterMesh.rotation.x = -Math.PI / 2;
				letterMesh.rotation.z = 0;
				letterMesh.visible = false;

				const bbox = new THREE.Box3().setFromObject(letterMesh);
				const letterWidth = bbox.max.x - bbox.min.x;
				offsetX += letterWidth + 0.05;

				newLetterMeshes.push(letterMesh);
				textMesh?.add(letterMesh);
			});

			setLetterMeshes(newLetterMeshes);

			newLetterMeshes.forEach((mesh, index) => {
				gsap.to(mesh.material, {
					opacity: 1,
					duration: 0.5,
					delay: index * 0.1,
					onStart: () => {
						mesh.visible = true;
					}
				});
			});

			setTimeout(() => {
				newLetterMeshes.reverse().forEach((mesh, reverseIndex) => {
					gsap.to(mesh.material, {
						opacity: 0,
						duration: 0.5,
						delay: reverseIndex * 0.1,
						onComplete: () => {
							textMesh?.remove(mesh);
							if (reverseIndex === newLetterMeshes.length - 1) {
								generateAndAnimateName();
							}
						}
					});
				});
			}, 3000);
		});
	}, []);

	useEffect(() => {
		generateAndAnimateName();
	}, [generateAndAnimateName, scene]);

	useEffect(() => {
		if (currentAnimation) {
			const action = actions[currentAnimation];
			action?.reset();
			action?.setLoop(THREE.LoopOnce, 1);
			if (action) {
				action.clampWhenFinished = true;
			}

			if (action) {
				if (playReverse) {
					action.time = action.getClip().duration;
					action.timeScale = -1;
				} else {
					action.timeScale = 1;
				}
			}

			action?.play();
		}
	}, [actions, currentAnimation, playReverse]);

	return <primitive object={scene} />;
}

export default function Main() {
	const pageTexts = [
		"Life Tracker isn't just another habit tracker. With Life Tracker, you're not just tracking habits—you're keeping tabs on your entire life.",
		'Whether you want to assign specific colors to certain intervals or...',
		'track any aspect of your life throughout the year with key-value pairs.',
		'You can create as many tables as you like for anything you want to track. Plus, with the option to choose between public and private settings, you can make only the ones you want visible to your friends.'
	];

	const [currentAnimation, setCurrentAnimation] = useState('');
	const [playReverse, setPlayReverse] = useState(false);
	const [animationIndex, setAnimationIndex] = useState(0);
	const [isFirstTime, setIsFirstTime] = useState(true);
	const [isPlaying, setIsPlaying] = useState(false);
	const textRef = useRef(null);
	const bookModelRef = useRef(null);
	const mobileBookModelRef = useRef(null);
	const [currentText, setCurrentText] = useState('');
	const hasIntersected = useRef(false);
	const buttonRef = useRef(null);
	const [showCanvas, setShowCanvas] = useState(false);
	const [showMobileCanvas, setShowMobileCanvas] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting && !hasIntersected.current) {
					loadGSAP().then((gsap) => {
						gsap.fromTo(
							textRef.current,
							{ text: '' },
							{
								text: pageTexts[0],
								duration: 5,
								ease: 'power2.inOut',
								onComplete: () => {
									gsap.to(buttonRef.current, {
										opacity: 1,
										duration: 1,
										ease: 'power2.inOut'
									});
								}
							}
						);
					});
					hasIntersected.current = true;
				}
			},
			{ threshold: 0.5 }
		);

		if (bookModelRef.current) {
			observer.observe(bookModelRef.current);
		}
		if (mobileBookModelRef.current) {
			observer.observe(mobileBookModelRef.current);
		}

		return () => {
			if (bookModelRef.current) {
				observer.unobserve(bookModelRef.current);
			}
			if (mobileBookModelRef.current) {
				observer.unobserve(mobileBookModelRef.current);
			}
		};
	}, []);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting) {
					setShowCanvas(true);
					observer.disconnect();
				}
			},
			{ threshold: 0.1 }
		);

		if (bookModelRef.current) {
			observer.observe(bookModelRef.current);
		}

		return () => observer.disconnect();
	}, []);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting) {
					setShowMobileCanvas(true);
					observer.disconnect();
				}
			},
			{ threshold: 0.1 }
		);

		if (mobileBookModelRef.current) {
			observer.observe(mobileBookModelRef.current);
		}

		return () => observer.disconnect();
	}, []);

	const changeText = (newIndex: number) => {
		loadGSAP().then((gsap) => {
			gsap.to(textRef.current, {
				duration: 0.5,
				onComplete: () => {
					setCurrentText('');
					gsap.fromTo(textRef.current, { text: '' }, { text: pageTexts[newIndex], duration: 5, ease: 'power2.inOut' });
				}
			});
		});
	};

	const playAnimation = (_animationName: string, reverse = false) => {
		setPlayReverse(reverse);
		setCurrentAnimation('actions.' + animationIndex);
	};

	const nextPage = () => {
		if (animationIndex >= 3) return;

		setIsPlaying(true);
		const nextAnimationIndex = animationIndex + 1;
		const nextAnimation = `actions.${nextAnimationIndex}`;

		playAnimation(nextAnimation, false);
		setAnimationIndex(nextAnimationIndex);
		setCurrentAnimation(nextAnimation);
		setIsFirstTime(false);
		changeText(nextAnimationIndex);

		setTimeout(() => setIsPlaying(false), 5000);
	};

	const prevPage = () => {
		if (currentAnimation === '') return;

		setIsPlaying(true);
		setTimeout(() => setIsPlaying(false), 5000);
		const parts = currentAnimation.split('.');
		const lastNumber = parseInt(parts[1], 10);

		if (lastNumber >= 1) {
			const prevNumber = lastNumber - 1;
			playAnimation(currentAnimation, true);
			setAnimationIndex(prevNumber);
			setIsFirstTime(false);
			changeText(prevNumber);
		}
	};
	return (
		<div className="w-full min-h-[1400px] gap-y-6 flex flex-col items-center bg-gradient-to-b from-[#8E4600] to-lt-lightCream dark:from-lt-dark-popupBackground dark:black px-2">
			<header className="text-[#333333] dark:text-[#8E4600] text-3xl sm:text-6xl font-extrabold text-center mt-20">
				Track Your Life <br /> With Your Online Journal
			</header>
			<h2 className="text-lg sm:text-xl font-semibold text-center text-[#5D4037]">
				Keep track of your daily life, <br /> decorate your journal with stickers, share with friends!
			</h2>
			<Link
				to="/auth/register"
				className="bg-[#FFD28C] text-[#4F4F4F] dark:bg-lt-dark-primary-bg dark:text-white py-4 2xl:py-5 text-xl px-10 2xl:px-14 rounded-full border-2 border-[#6E6E40] font-bold mt-5"
				type="button"
			>
				Try Life Tracker Free
			</Link>

			<div className="flex flex-col xl:flex-row w-full items-center justify-between ">
				<div className="text-area min-h-[300px] sm:min-h-[400px] flex items-center  xl:items-start relative max-w-[700px] 2xl:px-10">
					<p
						dangerouslySetInnerHTML={{ __html: currentText }}
						ref={textRef}
						className="text-content text-xl font-serif leading-relaxed text-[#5d4037] tracking-wide italic 2xl:text-2xl px-2 sm:px-4 py-10 2xl:px-0"
					></p>
					{isFirstTime && (
						<button
							aria-label="Open Journal"
							onClick={() => {
								nextPage();
							}}
							style={{ opacity: 0 }}
							ref={buttonRef}
							className="bg-[#FFD28C] text-[#4F4F4F] dark:bg-lt-dark-primary-bg dark:text-white py-4 2xl:py-5 text-base sm:text-xl px-4 sm:px-10 2xl:px-14 rounded-full border-2 border-[#6E6E40] font-bold absolute -bottom-10 lg:bottom-0 transform -translate-y-1/2 left-1/2 translate-x-[-50%]"
							type="button"
						>
							Open Journal
						</button>
					)}
				</div>

				<div className="hidden sm:block desk-bg">
					<button
						aria-label="Previous Page"
						className="prev-button z-40"
						disabled={currentAnimation === '' || isPlaying}
						onClick={() => {
							prevPage();
						}}
					>
						<IconChevronLeft className={`${isFirstTime ? 'hidden' : ''} text-[#f5deb3] rounded-full w-12 h-12`} />
					</button>

					<div ref={bookModelRef} className="book-model">
						{showCanvas && (
							<Canvas camera={{ position: [0, 20, 0], fov: 8 }}>
								<Suspense fallback={null}>
									<ambientLight intensity={0.5} />
									<directionalLight position={[0, 10, 0]} intensity={0.7} castShadow />
									<Model currentAnimation={currentAnimation} playReverse={playReverse} />
									<OrbitControls enableRotate={false} enableZoom={false} />
								</Suspense>
							</Canvas>
						)}
					</div>

					<button
						aria-label="Next Page"
						className="next-button z-40"
						disabled={animationIndex === 3 || isPlaying}
						onClick={() => {
							nextPage();
						}}
					>
						<IconChevronRight
							className={`${isFirstTime ? 'animate-fade' : ''} text-[#f5deb3] rounded-full w-12 h-12`}
						/>
					</button>
				</div>
				<div className="sm:hidden mobile-bg">
					<button
						aria-label="Previous Page"
						className="prev-button z-40"
						disabled={currentAnimation === '' || isPlaying}
						onClick={() => {
							prevPage();
						}}
					>
						<IconChevronLeft
							className={`${isFirstTime ? 'hidden' : ''} text-lt-beige sm:text-[#f5deb3] rounded-full w-12 h-12`}
						/>
					</button>

					<div ref={mobileBookModelRef} className="mobile-book">
						{showMobileCanvas && (
							<Canvas camera={{ position: [0, 20, 0], fov: 8 }}>
								<Suspense fallback={null}>
									<ambientLight intensity={0.5} />
									<directionalLight position={[0, 10, 0]} intensity={0.7} castShadow />
									<Model currentAnimation={currentAnimation} playReverse={playReverse} />
									<OrbitControls enableRotate={false} enableZoom={false} />
								</Suspense>
							</Canvas>
						)}
					</div>

					<button
						aria-label="Next Page"
						className="next-button z-40"
						disabled={animationIndex === 3 || isPlaying}
						onClick={() => {
							nextPage();
						}}
					>
						<IconChevronRight
							className={`${isFirstTime ? 'animate-fade' : ''} text-lt-beige sm:text-[#f5deb3] rounded-full w-12 h-12`}
						/>
					</button>
				</div>
			</div>
		</div>
	);
}
