import React, { useEffect, useRef, useState } from 'react';
import { ITable } from '../../../types/myTables.types';
import { IconDots, IconInfoCircle, IconLock, IconSwitchHorizontal, IconSwitchVertical } from '@tabler/icons-react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../states/store';
import {
	useDeleteTableMutation,
	useLazyGetTablesQuery,
	useUpdateActiveDaysMutation
} from '../../../services/api/myTables';
import {
	deleteTableSuccess,
	getTablesSuccess,
	setLoading,
	updateActiveDaysSuccess
} from '../../../states/table/myTablesSlice';
import { isCustomErrorResponse } from '../../../utils/errorUtils';
import toast from 'react-hot-toast';
import TableComponent from '../../../components/myTables/table/Table';
import { useLazyGetMeQuery } from '../../../services/api/auth';
import { IProfile, SubscriptionType, useLazyUpdateStreakDayQuery } from '../../../services/api/profile';
import {
	achievementEarnedSuccess,
	deleteTableFromProfileSuccess,
	getMyProfileSuccess,
	updateStreakDaySuccess
} from '../../../states/profile/profileSlice';
import { Tooltip } from 'react-tooltip';
import Button from '../../../components/button/button';

import noTable from '/src/assets/emptyStates/noTable.webp';
import CustomModule from '../../../components/customModule/customModule';
import AchievementToast from '../../../components/profile/achievements/AchievementToast';
import { IAchievement } from '../../../types/achievement.types';
import Subscription from '../Subscription/Subscription';
import { Helmet } from 'react-helmet';

export default function MyTables() {
	const dispatch = useDispatch();
	const { myTables, pagination, isLoading } = useSelector((state: RootState) => state.myTables);
	const { profile } = useSelector((state: RootState) => state.profile);
	const [getTablesRequest] = useLazyGetTablesQuery();
	const [deleteTableRequest, { isLoading: deleteTableIsLoading }] = useDeleteTableMutation();
	const [updateStreakDayRequest] = useLazyUpdateStreakDayQuery();
	const [getMyProfile] = useLazyGetMeQuery<IProfile>();
	const [updateActiveDaysRequest, { isLoading: updateActiveDayIsLoading }] = useUpdateActiveDaysMutation();
	const navigate = useNavigate();
	const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
	const [isHorizontal, setIsHorizontal] = useState(localStorage.getItem('isHorizontal') === 'true');
	const observer = useRef<IntersectionObserver | null>(null);
	const [currentPage, setCurrentPage] = useState(pagination.page);
	const [totalTables, setTotalTables] = useState(0);
	const [selectedTableId, setSelectedTableId] = useState('');

	const lastTableRef = (node: HTMLDivElement | null) => {
		if (isLoading) return;
		if (pagination.totalPages < currentPage && pagination.totalTables === totalTables) return;
		if (myTables && myTables.length > 0 && myTables.length === pagination.totalTables) return;
		if (observer.current) observer.current.disconnect();
		observer.current = new IntersectionObserver((entries) => {
			if (entries[0].isIntersecting) {
				setCurrentPage((prevPage) => prevPage + 1);
			}
		});
		if (node) observer.current.observe(node);
	};

	useEffect(() => {
		const fetchTables = () => {
			dispatch(setLoading(true));
			setTimeout(async () => {
				try {
					const response = await getTablesRequest({
						page: currentPage,
						limit: 10
					}).unwrap();
					if (response) {
						dispatch(
							getTablesSuccess({
								tables: response.tables,
								pagination: {
									page: response.pagination.page,
									totalPages: response.pagination.totalPages,
									totalTables: response.pagination.totalTables
								}
							})
						);
						setTotalTables(response.pagination.totalTables);
					}
				} catch (error) {
					if (isCustomErrorResponse(error)) {
						toast.error(error.data.message);
					} else if (error instanceof Error) {
						toast.error(error.message);
					} else {
						toast.error('An unknown error occurred');
					}
				} finally {
					dispatch(setLoading(false));
				}
			}, 300);
		};

		if (pagination.totalPages < currentPage && pagination.totalTables === totalTables) return;
		if (myTables && myTables.length > 0 && myTables.length === pagination.totalTables) return;
		if (myTables.length > 0 && myTables.length / 10 === currentPage) return;
		if (pagination.totalTables > totalTables && (totalTables / 10) % 1 !== 0) return;
		fetchTables();
	}, [currentPage, dispatch]);

	useEffect(() => {
		const updateStreakDay = async () => {
			const today = new Date().toISOString().split('T')[0];
			let lastUpdate = localStorage.getItem('lastStreakUpdate');

			if (!lastUpdate) {
				lastUpdate = '';
			}

			if (lastUpdate !== today) {
				try {
					const response = await updateStreakDayRequest().unwrap();
					if (response) {
						localStorage.setItem('lastStreakUpdate', today);
						if (response.message !== '') {
							toast.success(response.message);
							return;
						}
						dispatch(updateStreakDaySuccess(response.streakDay));
					}
				} catch (error) {
					if (isCustomErrorResponse(error)) {
						toast.error(error.data.message);
					} else if (error instanceof Error) {
						toast.error(error.message);
					} else {
						toast.error('An unknown error occurred. Please refresh the page.');
					}
				}
			}
		};

		updateStreakDay();
	}, [dispatch]);

	useEffect(() => {
		const checkProfile = async () => {
			if (profile && profile._id) {
				dispatch(getMyProfileSuccess(profile));
			} else {
				try {
					const response = await getMyProfile().unwrap();
					if (response) {
						dispatch(getMyProfileSuccess(response));
					}
				} catch (error) {
					if (isCustomErrorResponse(error)) {
						toast.error(error.data.message);
					} else if (error instanceof Error) {
						toast.error(error.message);
					} else {
						toast.error('An unknown error occurred');
					}
				}
			}
		};
		if (!isAuthenticated) return;
		checkProfile();
	}, [profile, isAuthenticated, dispatch]);

	const createTable = () => {
		if (profile?.subscription.subscriptionType === SubscriptionType.FREE && profile?.tables.length >= 1) {
			navigate('/dashboard/subscription');
			return;
		} else {
			navigate('/dashboard/my-tables/0?isCreate=true');
		}
	};

	const deleteTable = async () => {
		try {
			const response = await deleteTableRequest(selectedTableId).unwrap();
			if (response) {
				dispatch(deleteTableSuccess(selectedTableId));
				dispatch(deleteTableFromProfileSuccess(selectedTableId));
				setTimeout(() => {
					toast.success(response.message);
				}, 500);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred');
			}
		} finally {
			(document.getElementById('myTables-ays-modal') as HTMLDialogElement).close();
		}
	};

	const setTodaysColor = async (table: ITable, dataRangeId: string) => {
		try {
			setSelectedTableId(table._id as string);
			if (!table._id) return;
			if (!dataRangeId) return;
			const response = await updateActiveDaysRequest(dataRangeId).unwrap();
			if (response) {
				const matchingDataRange = response.data.dataRanges.find((dataRange) => dataRange._id === dataRangeId);
				if (matchingDataRange?.activeDays)
					dispatch(
						updateActiveDaysSuccess({
							_id: table._id,
							activeDay: matchingDataRange?.activeDays[matchingDataRange.activeDays.length - 1],
							dataRangeId: dataRangeId
						})
					);
				if (response.achievements.length > 0) {
					dispatch(achievementEarnedSuccess(response.achievements));
					response.achievements.forEach((achievement: IAchievement) => {
						toast(
							<AchievementToast
								title={achievement.title}
								description={achievement.description}
								threshold={achievement.threshold}
							/>
						);
					});
				}
				toast.success(response.message);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		}
	};

	const openAYSModule = (id: string) => {
		(document.getElementById('myTables-ays-modal') as HTMLDialogElement).showModal();
		setSelectedTableId(id);
	};

	return (
		<div className="relative my-16 md:m-4 h-screen flex flex-col bg-lt-cream border-2 border-lt-beige dark:bg-lt-dark-secondary-bg dark:border-lt-dark-secondary-border w-full rounded">
			<Helmet>
				<title>Life Tracker - My Tables</title>
				<meta
					name="description"
					content="Here you can view all your tables. You can navigate to your table details or you can quickly set the daily colors for all your tables from the colors next to your tables."
				/>
			</Helmet>
			<Subscription id="subscription-modal" />
			<CustomModule
				id="myTables-ays-modal"
				title="Are you sure you want to delete this table?"
				description="Deleting this table will permanently delete all the data associated with it. This action cannot be undone."
				buttonText="Delete Table"
				onButtonClick={() => {
					deleteTable();
				}}
				isLoading={deleteTableIsLoading}
			/>
			<div className="flex-shrink-0 fixed sm:static top-16 bg-lt-cream dark:bg-lt-dark-popupBackground left-0 right-0 z-10 flex flex-row items-center justify-between p-4">
				<div className="flex flex-row items-center gap-2">
					<h1 className="text-2xl sm:text-4xl font-bold">My Tables</h1>
					<IconInfoCircle
						data-tooltip-id="my-tables-quick-tip-tooltip"
						data-tooltip-content={`You can quickly set the daily colors for all your tables from the colors next to your tables.`}
						data-tooltip-place="bottom"
						className="w-6 h-6 text-lt-dark-primary-text dark:text-lt-dark-primary-text text-lt-dark-inputPlaceholder"
					/>
					<h6 className="hidden sm:block text-sm text-lt-dark-inputPlaceholder">
						<strong>Quick Tip:</strong> You can quickly set the daily colors for all your tables from the colors next to
						your tables.
					</h6>
					<Tooltip id="my-tables-quick-tip-tooltip" className="text-2xs sm:text-base" />
				</div>
				<div className="flex flex-row items-center gap-4">
					<Button variant="btn-primary-light" onClick={createTable} type="button">
						Create Table
					</Button>
					<div>
						<div className="flex items-center justify-center">
							{isHorizontal ? (
								<>
									<button
										data-tooltip-id="switch-to-vertical-tooltip"
										data-tooltip-content="Switch to vertical view"
										data-tooltip-place="top"
										onClick={() => {
											localStorage.setItem('isHorizontal', 'false');
											setIsHorizontal(false);
										}}
									>
										<IconSwitchHorizontal />
									</button>
									<Tooltip className="hidden sm:block z-50" id="switch-to-vertical-tooltip" delayHide={100} />
								</>
							) : (
								<>
									<button
										data-tooltip-id="switch-to-horizontal-tooltip"
										data-tooltip-content="Switch to horizontal view"
										data-tooltip-place="top"
										onClick={() => {
											localStorage.setItem('isHorizontal', 'true');
											setIsHorizontal(true);
										}}
									>
										<IconSwitchVertical />
									</button>
									<Tooltip className="hidden sm:block z-50" id="switch-to-horizontal-tooltip" delayHide={100} />
								</>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="flex-grow overflow-y-auto p-4 py-20 sm:py-0">
				<div className={`container my-10 sm:my-0 mx-auto ${isHorizontal ? 'p-0 md:p-4' : 'p-4'} `}>
					<div
						className={`w-full ${isHorizontal ? 'grid-cols-1 xl:grid-cols-2 gap-4 xl:gap-4' : 'grid-cols-1 base:grid-cols-2 2xl:grid-cols-3 gap-4'} grid place-items-center`}
					>
						<>
							{myTables && myTables.length && myTables.length > 0 ? (
								<React.Fragment>
									{myTables.map((table: ITable, index) => (
										<div
											key={index}
											ref={index === myTables.length - 1 ? lastTableRef : null}
											className={`relative p-2 rounded-md bg-lt-lightCream dark:bg-lt-dark-popupBorder ${isHorizontal ? 'w-full h-[300px] sm:h-[350px] md:h-[450px] xl:h-[400px] lg:px-2 lg:py-4 ' : 'w-10/12 2xl:w-10/12 min-w-[336px] h-[650px] lg:p-4'}`}
										>
											{updateActiveDayIsLoading && table._id === selectedTableId ? (
												<div className="h-full flex justify-center items-center">
													<span className="loading loading-spinner loading-lg"></span>
												</div>
											) : (
												<>
													<div className="flex justify-between items-center mb-2">
														<div className="flex gap-2 items-center">
															<Link
																to={`/dashboard/my-tables/${table._id}`}
																className={`text-center font-bold pl-2 hover:text-lt-beige ${table.title.length > 20 ? 'text-base sm:text-lg' : 'text-2xl'}`}
															>
																{table.title}
															</Link>
															<span className="text-lt-beige dark:text-lt-dark-popupText">
																{table.isTablePublic ? 'Public' : 'Private'}
															</span>
														</div>
														<div className="dropdown dropdown-end">
															<div
																tabIndex={0}
																role="button"
																className="btn btn-circle btn-ghost btn-xs text-lt-beige dark:text-lt-dark-popupText"
															>
																<IconDots />
															</div>
															<div
																tabIndex={0}
																className="card compact dropdown-content z-[1] shadow bg-lt-light-secondary-bg dark:bg-lt-dark-secondary-bg text-lt-light-secondary-text dark:text-lt-text-dark rounded-box w-40"
															>
																<div tabIndex={0} className="card-body">
																	<Link
																		to={`/dashboard/my-tables/${table._id}`}
																		state={{ isHorizontal: isHorizontal }}
																		className="btn btn-ghost hover:bg-lt-light-secondary-hover-bg dark:hover:bg-lt-dark-secondary-hover-bg"
																	>
																		Table Details
																	</Link>
																	{deleteTableIsLoading ? (
																		<span className="loading loading-spinner loading-sm"></span>
																	) : (
																		<button
																			disabled={deleteTableIsLoading}
																			onClick={() => openAYSModule(table._id as string)}
																			className="btn btn-ghost hover:bg-lt-light-secondary-hover-bg dark:hover:bg-lt-dark-secondary-hover-bg"
																		>
																			Delete Table
																		</button>
																	)}
																</div>
															</div>
														</div>
													</div>

													<div
														className={`flex ${isHorizontal ? 'flex-col h-[225px] sm:h-[290px] md:h-[360px] xl:h-[320px] justify-between' : ''}`}
													>
														<TableComponent isHorizontal={isHorizontal} newTableData={table} componentUsed="myTables" />
														<div
															className={`grid ${isHorizontal ? 'w-full grid-rows-1 grid-flow-col' : 'w-3/12 grid-cols-1'}`}
														>
															{table.dataRanges.map((dataRange, index) => (
																<div
																	key={index}
																	className={`flex justify-center items-center sm:justify-start ${isHorizontal ? 'flex-col ' : 'flex-col sm:flex-row sm:ml-1'}`}
																>
																	<button
																		disabled={updateActiveDayIsLoading}
																		onClick={() => {
																			if (dataRange._id) setTodaysColor(table, dataRange._id);
																		}}
																		style={{ backgroundColor: dataRange.hexColorCode }}
																		className="min-w-6 min-h-6 rounded-sm"
																	></button>
																	<div
																		data-tooltip-id="my-tables-dataRange-tooltip"
																		data-tooltip-content={dataRange.range + ' ' + (dataRange?.unit ?? '')}
																		data-tooltip-place="top"
																		className={`flex sm:gap-1 text-center sm:ml-2 ${dataRange.range.length > 10 ? 'text-xs flex-col' : 'text-sm'} ${dataRange.unit?.length && dataRange.unit?.length > 10 ? 'text-xs flex-col' : 'text-sm'}`}
																	>
																		<h6>
																			{dataRange.range.length > 10
																				? `${dataRange.range.slice(0, 10)}...`
																				: dataRange.range}
																		</h6>
																		<h6>
																			{dataRange.unit?.length && dataRange.unit?.length > 10
																				? `${dataRange.unit.slice(0, 10)}...`
																				: dataRange.unit}
																		</h6>
																		<Tooltip className="z-50" id="my-tables-dataRange-tooltip" />
																	</div>
																</div>
															))}
														</div>
													</div>
													{profile.subscription.subscriptionType === SubscriptionType.FREE && index > 0 && (
														<button
															onClick={() => {
																(document.getElementById('subscription-modal') as HTMLDialogElement).showModal();
															}}
															className="absolute inset-0 bg-gray-700 bg-opacity-75 p-4 flex flex-col rounded-lg justify-center items-center text-white font-bold text-center"
														>
															<IconLock className="mb-2" />
															You have reached the limit of creating tables with the free plan. Upgrade to create more
															tables.
														</button>
													)}
												</>
											)}
										</div>
									))}
								</React.Fragment>
							) : (
								<>
									{!isLoading && (
										<div className="base:col-start-1 base:col-end-3 xl:col-start-1">
											<div className="flex flex-col">
												<img
													className="max-w-[350px] max-h-[280px] sm:w-[450px] sm:h-[360px]"
													src={noTable}
													alt="No table"
												/>
												<span className="mt-4">No tables found. Click on the button above to create a new table.</span>
											</div>
										</div>
									)}
								</>
							)}
						</>
					</div>
				</div>
				{isLoading && (
					<div className="col-span-3 flex justify-center items-center h-[100px]">
						<span className="loading loading-spinner loading-lg text-center"></span>
					</div>
				)}
			</div>
		</div>
	);
}
