import { Link } from 'react-router-dom';
import { Link as ReactLink } from 'react-scroll';

export default function Navbar() {
	return (
		<div className="sticky top-0 z-50 w-full h-[70px] px-4 sm:px-12 flex items-center justify-between border-b border-[#8E6B4B] dark:border-lt-dark-secondary-border bg-[#8E4600] dark:bg-lt-dark-secondary-bg">
			<div>
				<a href="" className="font-bold text-base sm:text-xl text-[#F8D7A3]">
					Life Tracker
				</a>
			</div>
			<nav className="w-5/12 md:w-1/4 ">
				<ul className="flex text-xs sm:text-base font-semibold justify-between text-[#F8D7A3]">
					<li>
						<ReactLink
							className="cursor-pointer"
							to="features"
							smooth={true}
							duration={500}
							role="button"
							aria-label="Scroll to features"
						>
							Features
						</ReactLink>
					</li>
					<li>
						<ReactLink
							className="cursor-pointer"
							to="faq"
							smooth={true}
							duration={500}
							role="button"
							aria-label="Scroll to FAQ"
						>
							FAQ
						</ReactLink>
					</li>
					<li>
						<ReactLink
							className="cursor-pointer"
							to="pricing"
							smooth={true}
							duration={500}
							role="button"
							aria-label="Scroll to pricing"
						>
							Pricing
						</ReactLink>
					</li>
				</ul>
			</nav>
			<div>
				<Link
					className="bg-[#FFD28C] text-[#4F4F4F] py-2 px-4 sm:px-8 rounded-full border-2 border-[#6E6E40] dark:bg-lt-dark-primary-bg dark:text-white font-bold"
					type="button"
					to="/auth/login"
				>
					Sign In
				</Link>
			</div>
		</div>
	);
}
