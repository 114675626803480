import { ButtonProps } from './button.types';

export default function Button({ type, variant, children, onClick, className, disabled, ...rest }: ButtonProps) {
	const getVariantClass = (variant: string) => {
		switch (variant) {
			case 'btn-primary-light':
				return 'btn-primary-light dark:btn-primary-dark';
			case 'btn-secondary-light':
				return 'btn-secondary-light dark:btn-primary-dark';
			case 'btn-primary-dark':
				return 'btn-primary-dark dark:btn-primary-light';
			case 'btn-secondary-dark':
				return 'btn-secondary-dark dark:btn-secondary-light';
			default:
				return '';
		}
	};
	return (
		<button
			type={type}
			onClick={onClick}
			className={`btn  ${getVariantClass(variant as string)} rounded-lg text-lg  focus:outline-none focus:ring-2 focus:ring-offset-2 ${className}`}
			disabled={disabled}
			{...rest}
		>
			{children}
		</button>
	);
}
