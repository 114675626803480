import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IShopItem } from '../../types/shop.types';

interface Pagination {
	page: number;
	totalPages: number;
	totalShopItems: number;
}

interface ShopItemState {
	shopItems: { [page: number]: IShopItem[] };
	loadedPages: number[];
	pagination: Pagination;
}

const initialState: ShopItemState = {
	shopItems: {},
	loadedPages: [],
	pagination: { page: 1, totalPages: 1, totalShopItems: 0 }
};

const shopItemsSlice = createSlice({
	name: 'shopItems',
	initialState,
	reducers: {
		getShopItemsSuccess(state, action: PayloadAction<{ items: IShopItem[]; pagination: Pagination }>) {
			const { items, pagination } = action.payload;
			state.shopItems[pagination.page] = items;
			if (!state.loadedPages.includes(pagination.page)) {
				state.loadedPages.push(pagination.page);
			}
			state.pagination = pagination;
		}
	}
});

export const { getShopItemsSuccess } = shopItemsSlice.actions;
export default shopItemsSlice.reducer;
