import { forwardRef } from 'react';
import { InputProps } from './input.types';
import './style.css';
const Input = forwardRef<HTMLInputElement, InputProps>(
	(
		{
			type,
			placeholder,
			name,
			value,
			onChange,
			required,
			className,
			icon,
			disabled,
			onKeydown,
			onPaste,
			maxLength,
			...rest
		},
		ref
	) => {
		return (
			<div className="relative w-full max-w-xs">
				<input
					maxLength={maxLength ?? 30}
					onKeyDown={onKeydown}
					onPaste={onPaste}
					ref={ref}
					disabled={disabled}
					type={type}
					placeholder={placeholder}
					name={name}
					className={`input input-bordered dark:autofill:caret-white bg-lt-light-inputBackground border-lt-light-inputBorder text-lt-light-inputText placeholder:text-lt-light-inputPlaceholder dark:bg-lt-dark-inputBackground dark:border-lt-dark-inputBorder dark:text-lt-dark-inputText dark:placeholder:text-lt-dark-inputPlaceholder rounded-md w-full outline-none ${
						icon ? 'pr-10' : ''
					} ${className}`}
					required={required}
					value={value}
					onChange={onChange}
					{...rest}
				/>
				{icon && <div className="absolute inset-y-0 right-0 pr-3 flex items-center dark:text-white">{icon}</div>}
			</div>
		);
	}
);

export default Input;
