import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IActivity, ILeaderBoard, IUser } from '../../services/api/social';

interface SocialState {
	users: IUser[];
	leaderBoard: ILeaderBoard[];
	activities: IActivity[];
}

const initialState: SocialState = {
	users: [],
	leaderBoard: [],
	activities: []
};

const socialSlice = createSlice({
	name: 'social',
	initialState,
	reducers: {
		searchUsersSuccess(state, action: PayloadAction<IUser[]>) {
			state.users = action.payload;
		},
		getLeaderBoardSuccess(state, action: PayloadAction<ILeaderBoard[]>) {
			state.leaderBoard = action.payload;
		},
		getFriendsActivitiesSuccess(state, action: PayloadAction<IActivity[]>) {
			state.activities = action.payload;
		}
	}
});

export const { searchUsersSuccess, getLeaderBoardSuccess, getFriendsActivitiesSuccess } = socialSlice.actions;
export default socialSlice.reducer;
