import {
	BaseQueryFn,
	FetchArgs,
	fetchBaseQuery,
	FetchBaseQueryError,
	FetchBaseQueryMeta
} from '@reduxjs/toolkit/query/react';
import { logoutSuccess } from '../../states/auth/authSlice';
import { persistor } from '../../states/store';

const baseQuery = fetchBaseQuery({
	baseUrl:
		process.env.NODE_ENV === 'development' ? import.meta.env.VITE_API_URL_TEST : import.meta.env.VITE_API_URL_PROD,
	credentials: 'include'
});

const baseQueryWithReauth: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError,
	{},
	FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
	const result = await baseQuery(args, api, extraOptions);

	if (result.error && result.error.status === 401) {
		api.dispatch(logoutSuccess());
		window.location.href = '/auth/login';
		await persistor.purge();
	}

	return result;
};

export default baseQueryWithReauth;
