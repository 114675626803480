import { useEffect, useState } from 'react';
import { IDataRange, ITable } from '../../../../types/myTables.types';
import Button from '../../../../components/button/button';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Input from '../../../../components/input/input';
import './TableDetails.css';
import TableComponent from '../../../../components/myTables/table/Table';
import {
	useCreateTableMutation,
	useDeleteTableMutation,
	useDeleteTodaysColorMutation,
	useLazyGetTableQuery,
	useUpdateActiveDaysMutation,
	useUpdateIsTablePublicMutation,
	useUpdateTableNotesMutation
} from '../../../../services/api/myTables';
import toast from 'react-hot-toast';
import { isCustomErrorResponse } from '../../../../utils/errorUtils';
import { useDispatch, useSelector } from 'react-redux';
import {
	createTableSuccess,
	deleteTableSuccess,
	deleteTodaysColorSuccess,
	updateActiveDaysSuccess,
	updateIsTablePublicSuccess,
	updateTableNotesSuccess
} from '../../../../states/table/myTablesSlice';
import { RootState } from '../../../../states/store';
import NewDataRange from '../../../../components/myTables/newDataRange/NewDataRange';
import { IAchievement } from '../../../../types/achievement.types';
import AchievementToast from '../../../../components/profile/achievements/AchievementToast';
import {
	achievementEarnedSuccess,
	addNewTableToProfile,
	deleteTableFromProfileSuccess
} from '../../../../states/profile/profileSlice';
import { IconArrowNarrowLeft, IconHelpOctagon, IconInfoCircle, IconTrashX } from '@tabler/icons-react';
import { Doughnut, Line } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	ArcElement,
	Tooltip,
	Legend,
	CategoryScale,
	LinearScale,
	Title,
	PointElement,
	LineElement
} from 'chart.js';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import CustomModule from '../../../../components/customModule/customModule';
import { SubscriptionType } from '../../../../services/api/profile';
import { Helmet } from 'react-helmet';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title);

export default function TableDetails() {
	const { tableId } = useParams<{ tableId: string }>();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [deleteTableRequest, { isLoading: deleteTableIsLoading }] = useDeleteTableMutation();
	const [getTable, { error, isLoading: getTableIsLoading }] = useLazyGetTableQuery();
	const [updateIsTablePublicRequest, { isLoading: updateTableIsPublicIsLoading }] = useUpdateIsTablePublicMutation();
	const [updateTableNotesRequest, { isLoading: updateTableNotesIsLoading }] = useUpdateTableNotesMutation();
	const [updateActiveDaysRequest, { isLoading: updateActiveDaysIsLoading }] = useUpdateActiveDaysMutation();
	const [lastSelectedColor, setLastSelectedColor] = useState<string>('');
	const [createTableRequest, { isLoading: createTableIsLoading }] = useCreateTableMutation();
	const myTables = useSelector((state: RootState) => state.myTables.myTables);
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const isCreate = searchParams.get('isCreate') === 'true';
	const [isTodaysColorSelected, setIsTodaysColorSelected] = useState(false);
	const [isNotesUpdated, setIsNotesUpdated] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [deleteTodaysColorRequest] = useDeleteTodaysColorMutation();
	const profile = useSelector((state: RootState) => state.profile.profile);
	const { isHorizontal } = location.state || {};

	useEffect(() => {
		if (profile.subscription.subscriptionType === SubscriptionType.FREE && profile.tables.length > 1 && isCreate) {
			toast.error(
				'You have reached the maximum number of tables allowed for free users. Please upgrade your subscription to create more tables.'
			);
			navigate('/dashboard/subscription');
		}
	}, [profile.subscription.subscriptionType, profile.tables.length, isCreate]);

	const toggleDrawer = () => {
		setIsOpen((prevState) => !prevState);
	};
	const [newTableData, setNewTableData] = useState<ITable>({
		title: '',
		isTablePublic: false,
		notes: '',
		dataRanges: [
			{
				hexColorCode: '',
				range: '',
				unit: ''
			},
			{
				hexColorCode: '',
				range: '',
				unit: ''
			}
		]
	});
	const theme = localStorage.getItem('theme');
	const activeDayCountByRange = newTableData.dataRanges.map((dataRange) => dataRange.activeDays?.length || 0);
	const labelsDoughnutData = newTableData.dataRanges.map((dataRange) => dataRange.range);
	const backgroundColors = newTableData.dataRanges.map((dataRange) => dataRange.hexColorCode);
	const hoverOffsets = newTableData.dataRanges.map((dataRange) => dataRange.activeDays?.length || 0);

	const doughnutData = {
		labels: labelsDoughnutData,
		datasets: [
			{
				label: 'Active Days Count',
				data: activeDayCountByRange,
				backgroundColor: backgroundColors,
				hoverOffset: hoverOffsets
			}
		]
	};

	const optionsDoughnutData = {
		plugins: {
			tooltip: {
				callbacks: {
					title: function (tooltipItems: any) {
						const tooltipItem = tooltipItems[0];
						const dataRange = newTableData.dataRanges[tooltipItem.dataIndex];
						return dataRange.unit ? `${dataRange.range} ${dataRange.unit}` : dataRange.range;
					},
					label: function (tooltipItem: any) {
						return `Count: ${tooltipItem.raw}`;
					}
				}
			},
			legend: {
				labels: {
					color: theme === 'dark' ? 'rgba(255, 255, 255, 0.87)' : 'rgba(0, 0, 0, 0.87)'
				}
			}
		}
	};
	const getMonthlyPerformance = (dataRanges: IDataRange[]) => {
		const monthlyData: { [key: string]: number } = {};

		dataRanges.forEach((dataRange) => {
			if (dataRange.activeDays) {
				dataRange.activeDays.forEach((activeDay) => {
					const monthYear = `${activeDay.monthIndex + 1}-${new Date().getFullYear()}`;
					if (!monthlyData[monthYear]) {
						monthlyData[monthYear] = 0;
					}
					monthlyData[monthYear]++;
				});
			}
		});

		const labels = Object.keys(monthlyData).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
		const data = labels.map((label) => monthlyData[label]);

		return { labels, data };
	};

	const { labels, data } = getMonthlyPerformance(newTableData.dataRanges);

	const lineChartData = {
		labels,
		datasets: [
			{
				label: 'Number of Active Days',
				data,
				fill: false,
				borderColor: 'rgb(75, 192, 192)',
				tension: 0.1
			}
		]
	};

	const optionsLineChart = {
		responsive: true,
		plugins: {
			legend: {
				position: 'top' as const,
				labels: {
					color: theme === 'dark' ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)'
				}
			},
			title: {
				display: true,
				text: 'Monthly Performance',
				color: theme === 'dark' ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)'
			},
			scales: {
				x: {
					ticks: {
						color: theme === 'dark' ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)'
					}
				},
				y: {
					ticks: {
						color: theme === 'dark' ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)'
					}
				}
			}
		}
	};

	useEffect(() => {
		const fetchTable = async () => {
			if (tableId && tableId !== '0') {
				const table = myTables.find((table) => table._id === tableId);
				if (table) {
					setNewTableData(table);
				} else {
					try {
						const response = await getTable(tableId).unwrap();
						if (response.achievements.length > 0) {
							setNewTableData(response.data);
						}
					} catch (err) {
						if (isCustomErrorResponse(error)) {
							toast.error(error.data.message);
						} else if (error instanceof Error) {
							toast.error(error.message);
						} else {
							toast.error('An unknown error occurred. Please try again later.');
						}
						navigate('/dashboard/not-found');
					}
				}
			}
		};
		if (!profile.tables.includes(tableId as string)) return;
		fetchTable();
	}, [myTables, tableId]);

	useEffect(() => {
		const checkIsTodaysColorSelected = () => {
			const date = new Date();
			const monthIndex = String(date.getMonth() + 1);
			const todaysIndex = String(date.getDate());

			const activeDay = newTableData.dataRanges
				.map((dataRange) => dataRange.activeDays)
				.flat()
				.find(
					(activeDay) => activeDay?.dayIndex === Number(todaysIndex) && activeDay.monthIndex === Number(monthIndex)
				);

			if (activeDay) {
				setIsTodaysColorSelected(true);
			}
		};
		checkIsTodaysColorSelected();
	}, [newTableData]);

	const deleteTable = async () => {
		try {
			if (!newTableData._id) return;
			const response = await deleteTableRequest(newTableData._id).unwrap();
			if (response) {
				navigate('/dashboard/my-tables');
				dispatch(deleteTableSuccess(newTableData._id));
				dispatch(deleteTableFromProfileSuccess(newTableData._id));
				setTimeout(() => {
					toast.success(response.message);
				}, 500);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		} finally {
			(document.getElementById('tableDetails-ays-modal') as HTMLDialogElement).close();
		}
	};

	const updateIsTablePublic = async (id: string) => {
		const updatedTable = { ...newTableData, _id: id };
		try {
			const response = await updateIsTablePublicRequest(id).unwrap();
			if (response) {
				setNewTableData({
					...updatedTable,
					isTablePublic: response.data.isTablePublic
				});
				updatedTable.isTablePublic = response.data.isTablePublic;
				dispatch(
					updateIsTablePublicSuccess({
						_id: id,
						isTablePublic: response.data.isTablePublic
					})
				);
				if (response.achievements.length > 0) {
					dispatch(achievementEarnedSuccess(response.achievements));
					response.achievements.forEach((achievement: IAchievement) => {
						toast(
							<AchievementToast
								title={achievement.title}
								description={achievement.description}
								threshold={achievement.threshold}
							/>
						);
					});
				}
				toast.success(`Table changed to ${!newTableData.isTablePublic === false ? 'private' : 'public'} successfully`);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		}
	};

	const setTodaysColor = async (dataRangeId: string) => {
		try {
			if (!newTableData._id) return;
			if (!dataRangeId) return;
			const response = await updateActiveDaysRequest(dataRangeId).unwrap();
			if (response) {
				const matchingDataRange = response.data.dataRanges.find((dataRange) => dataRange._id === dataRangeId);
				if (matchingDataRange?.activeDays) {
					dispatch(
						updateActiveDaysSuccess({
							_id: newTableData._id,
							activeDay: matchingDataRange?.activeDays[matchingDataRange.activeDays.length - 1],
							dataRangeId: dataRangeId
						})
					);
				}
				if (response.achievements.length > 0) {
					dispatch(achievementEarnedSuccess(response.achievements));
					response.achievements.forEach((achievement: IAchievement) => {
						toast(
							<AchievementToast
								title={achievement.title}
								description={achievement.description}
								threshold={achievement.threshold}
							/>
						);
					});
				}
				toast.success(response.message);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		}
	};

	const clearTodaysSelection = async () => {
		try {
			if (!newTableData._id) return;
			const date = new Date();
			const monthIndex = String(date.getMonth() + 1);
			const todaysIndex = String(date.getDate());

			const activeDay = newTableData.dataRanges
				.map((dataRange) => dataRange.activeDays)
				.flat()
				.find(
					(activeDay) => activeDay?.dayIndex === Number(todaysIndex) && activeDay.monthIndex === Number(monthIndex)
				);

			if (!activeDay) {
				toast.error("Today's color is not selected");
				return;
			}
			const activeDayId = activeDay._id;
			const dataRangeId = newTableData.dataRanges.find((dataRange) =>
				dataRange.activeDays?.find((activeDay) => activeDay._id === activeDayId)
			)?._id;
			const response = await deleteTodaysColorRequest({
				dataRangeId,
				activeDayId
			}).unwrap();
			if (response) {
				dispatch(
					deleteTodaysColorSuccess({
						_id: newTableData._id,
						dataRangeId,
						activeDayId
					})
				);
				setIsTodaysColorSelected(false);
				toast.success(response.message);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		}
	};

	const createTable = async () => {
		const dataRanges = newTableData.dataRanges.filter((dataRange) => dataRange.range);
		dataRanges.forEach((dataRange) => {
			if (dataRange.range[0] === '-') {
				dataRange.range = dataRange.range.slice(1);
			}
			if (dataRange.range[dataRange.range.length - 1] === '-') {
				dataRange.range = dataRange.range.slice(0, dataRange.range.length - 1);
			}
		});
		const updatedTable = { ...newTableData, dataRanges };
		try {
			delete updatedTable.notes;
			const response = await createTableRequest(updatedTable).unwrap();
			if (response) {
				dispatch(createTableSuccess(response.data));
				dispatch(addNewTableToProfile(response.data._id as string));
				if (response.achievements.length > 0) {
					dispatch(achievementEarnedSuccess(response.achievements));
					response.achievements.forEach((achievement: IAchievement) => {
						toast(
							<AchievementToast
								title={achievement.title}
								description={achievement.description}
								threshold={achievement.threshold}
							/>
						);
					});
				}
				toast.success(response.message);
				setNewTableData({
					title: '',
					isTablePublic: false,
					notes: '',
					dataRanges: [
						{
							hexColorCode: '',
							range: '',
							unit: ''
						},
						{
							hexColorCode: '',
							range: '',
							unit: ''
						}
					]
				});
				navigate('/dashboard/my-tables');
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		}
	};

	const handleNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setNewTableData({ ...newTableData, notes: value });
		setIsNotesUpdated(value !== '');
	};

	const handleUpdateNotes = async (tableId: string, notes: string) => {
		try {
			const response = await updateTableNotesRequest({
				id: tableId,
				notes
			}).unwrap();
			if (response) {
				if (response.data.notes)
					dispatch(
						updateTableNotesSuccess({
							_id: tableId,
							notes: response.data.notes
						})
					);
				toast.success(response.message);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		}
		setIsNotesUpdated(false);
	};

	const calculateConsistency = (dataRanges: IDataRange[], year: number): string => {
		if (!dataRanges || dataRanges.length === 0) {
			return '0';
		}

		const allActiveDays = dataRanges
			.flatMap(
				(dataRange) => dataRange.activeDays?.map((day) => new Date(year, day.monthIndex - 1, day.dayIndex)) || []
			)
			.filter((date) => !isNaN(date.getTime()))
			.sort((a, b) => a.getTime() - b.getTime());

		if (allActiveDays.length === 0) {
			return '0';
		}

		const firstActiveDay = allActiveDays[0];
		const today = new Date();

		const totalDays = Math.ceil((today.getTime() - firstActiveDay.getTime()) / (1000 * 60 * 60 * 24));

		const filledDays = allActiveDays.length;

		const consistency = (filledDays / totalDays) * 100;

		return consistency.toFixed(2);
	};

	const consistency = calculateConsistency(newTableData.dataRanges, 2024);

	const openAYSModule = () => {
		(document.getElementById('tableDetails-ays-modal') as HTMLDialogElement).showModal();
	};

	return (
		<div
			className={`h-screen overflow-y-auto justify-center md:m-4 md:mx-0 bg-lt-cream border border-lt-beige dark:bg-lt-dark-secondary-bg dark:border-lt-dark-secondary-border w-full rounded ${isHorizontal ? '' : ' pt-4'} pb-16 lg:flex lg:pt-0 lg:pb-5 h-full`}
		>
			<Helmet>
				<title>Life Tracker - Table Details</title>
				<meta name="description" content="View and manage your table details." />
			</Helmet>
			<CustomModule
				id="tableDetails-ays-modal"
				title="Are you sure you want to delete this table?"
				description="Deleting this table will permanently delete all the data associated with it. This action cannot be undone."
				buttonText="Delete Table"
				onButtonClick={() => {
					deleteTable();
				}}
				isLoading={deleteTableIsLoading}
			/>
			<div className="relative flex justify-center w-full max-w-[2000px] max-h-[2000px] sm:max-h-[1000px] py-12 sm:py-0">
				<Link
					to="/dashboard/my-tables"
					className={`absolute ${isHorizontal ? 'top-12 left-2' : 'top-9 left-6'}  md:top-2 `}
				>
					<IconArrowNarrowLeft className="text-lt-beige w-14 h-14 sm:w-20 sm:h-20" />
				</Link>
				{getTableIsLoading ? (
					<span className="loading loading-spinner loading-lg"></span>
				) : (
					<div className={`flex  ${isHorizontal ? 'flex-wrap sm:p-5' : ' flex-col p-5'} md:flex-row w-full`}>
						<div className="hidden sm:block">
							<Drawer
								open={isOpen}
								onClose={toggleDrawer}
								direction="bottom"
								className="bg-lt-lightCream dark:bg-lt-dark-primary-bg flex justify-center"
							>
								<div className="flex justify-around gap-2 w-full max-w-[2000px] items-center py-5">
									<div className="w-[200px] h-[200px]">
										<Doughnut className="doughnut dark:text-white" data={doughnutData} options={optionsDoughnutData} />
									</div>
									<div>
										<Line data={lineChartData} options={optionsLineChart} />
									</div>
									<div className="flex flex-col h-[200px] justify-center gap-2 text-lt-beige dark:text-white">
										<strong>Consistency:</strong>
										<div className="radial-progress" style={{ '--value': Number(consistency) }} role="progressbar">
											{consistency}%
										</div>
									</div>
								</div>
							</Drawer>
						</div>

						<div className={` ${isHorizontal ? 'lg:w-full flex order-1 ' : ' lg:w-2/5 order-2'}  w-full `}>
							<div
								className={`flex flex-col   ${isHorizontal ? 'items-center justify-center sm:justify-center lg:justify-center' : 'mt-8 justify-start sm:justify-center'} w-full`}
							>
								{isHorizontal && (
									<div className="w-full flex justify-between items-center lg:w-8/12 2xl:w-5/12 px-6 mt-10 sm:mt-24 md:mt-14">
										<h1 className="font-bold text-2xl sm:text-4xl lg:text-5xl mb-5 ">{newTableData.title}</h1>
										<div className="h-full sm:h-1/5 flex flex-col sm:justify-end justify-start">
											<div className="flex  justify-between">
												<button
													data-tooltip-id="delete-table-tooltip"
													data-tooltip-content="Delete this table"
													data-tooltip-place="top"
													disabled={deleteTableIsLoading}
													type="button"
													onClick={deleteTable}
													className=" w-1/12 sm:w-5/12 disabled:text-gray-500 text-red-500 "
												>
													<IconTrashX className="w-5 h-5 sm:w-auto sm:h-auto " />
													<ReactTooltip id="delete-table-tooltip" />
												</button>
											</div>
										</div>
									</div>
								)}
								<TableComponent
									isHorizontal={isHorizontal}
									newTableData={newTableData}
									lastSelectedColor={lastSelectedColor}
									componentUsed="tableDetails"
								/>
							</div>
						</div>

						<div
							className={`w-full  px-1 sm:px-6 flex flex-col ${isHorizontal ? ' w-full h-auto lg:w-8/12 2xl:w-5/12 justify-around 2xl:px-4 order-2 mx-auto' : ' lg:w-3/5  order-1 lg:mt-8'}  `}
						>
							<div className="flex justify-between min-h-20">
								{isCreate ? (
									<>
										<Input
											value={newTableData.title}
											name="title"
											type="text"
											placeholder="Enter Table Title"
											className="p-2 w-2/4 border border-lt-beige rounded-md mt-6"
											onChange={(e) => setNewTableData({ ...newTableData, title: e.target.value })}
										/>
									</>
								) : (
									<div className="flex justify-between items-center w-11/12">
										<div className="flex flex-col lg:flex-row items-start start:items-center gap-2 mt-6 sm:mt-24 md:mt-12 lg:mt-10">
											{!isHorizontal && (
												<h1 className="font-bold text-2xl sm:text-4xl lg:text-5xl">{newTableData.title}</h1>
											)}

											<div className="form-control">
												<label
													data-tooltip-id="table-is-public-tooltip"
													data-tooltip-content="If it's public, it will be shown in your journal."
													data-tooltip-place="top"
													className="label cursor-pointer"
												>
													<input
														disabled={updateTableIsPublicIsLoading}
														type="checkbox"
														checked={newTableData.isTablePublic}
														onChange={() => {
															if (newTableData._id) updateIsTablePublic(newTableData._id);
														}}
														className="w-4 h-4 sm:w-6 sm:h-6 rounded-sm sm:rounded-md checkbox [--chkbg:theme(colors.lt-beige)] [--chkfg:#FAEDCD] dark:[--chkbg:theme(colors.lt-dark-primary-bg)] dark:[--chkfg:#FFFFFF] checked:border-none"
													/>

													<span className="text-xs sm:text-base label-text text-lt-dark-inputPlaceholder ml-2">
														{!newTableData.isTablePublic === false ? 'Make it Private' : 'Make it Public'}
													</span>
													<ReactTooltip id="table-is-public-tooltip" />
												</label>
											</div>
										</div>
										{!isCreate && !isHorizontal && (
											<div className="h-full flex flex-col justify-center w-1/12">
												<div className="flex  justify-between">
													<button
														data-tooltip-id="delete-table-tooltip"
														data-tooltip-content="Delete this table"
														data-tooltip-place="top"
														disabled={deleteTableIsLoading}
														type="button"
														onClick={() => openAYSModule()}
														className=" w-1/12 sm:w-5/12 disabled:text-gray-500 text-red-500 "
													>
														<IconTrashX className="w-5 h-5 sm:w-auto sm:h-auto " />
														<ReactTooltip id="delete-table-tooltip" />
													</button>
												</div>
											</div>
										)}
									</div>
								)}
							</div>
							{isCreate ? (
								<NewDataRange
									newTableData={newTableData}
									setNewTableData={setNewTableData}
									setLastSelectedColor={setLastSelectedColor}
								/>
							) : (
								<div
									className={`${isHorizontal ? 'sm:h-2/5 lg:w-full' : ' sm:h-2/5 lg:w-2/3'} flex  flex-col w-full  `}
								>
									{!isTodaysColorSelected ? (
										<div className="flex items-center gap-2 justify-center lg:justify-start">
											<IconInfoCircle className="w-6 h-6 text-lt-dark-primary-text dark:text-lt-dark-primary-text text-lt-dark-inputPlaceholder" />
											<h6 className="text-xs sm:text-sm text-lt-dark-inputPlaceholder">
												You haven't selected today's color yet. Click on the color you want to set as today's color.
											</h6>
										</div>
									) : (
										<div className="flex items-center gap-2 justify-center lg:justify-start">
											<IconInfoCircle className="w-6 h-6 text-lt-dark-primary-text dark:text-lt-dark-primary-text text-lt-dark-inputPlaceholder" />
											<h6 className="text-xs sm:text-sm  text-lt-dark-inputPlaceholder">
												You have already selected today's color. Come back tomorrow to select a new color.
											</h6>
											<button
												onClick={() => clearTodaysSelection()}
												className="bg-red-500 text-2xs sm:text-base text-white px-2 py-2 rounded-md"
												type={'button'}
											>
												Clear todays selection
											</button>
										</div>
									)}
									<div className={`h-full grid ${isHorizontal ? 'grid-cols-2 sm:grid-cols-3' : 'grid-cols-2'} gap-2`}>
										{newTableData.dataRanges.map((dataRange, index) => (
											<div key={index} className="flex items-center mt-5">
												<button
													disabled={updateActiveDaysIsLoading || isTodaysColorSelected}
													onClick={() => {
														if (dataRange._id) setTodaysColor(dataRange._id);
													}}
													style={{
														backgroundColor: dataRange.hexColorCode
													}}
													className="w-6 h-6 sm:w-10 sm:h-10 rounded-sm flex-shrink-0"
												></button>
												<div className="ml-2 flex items-center">
													<span className="text-base sm:text-lg whitespace-nowrap overflow-hidden text-ellipsis">
														{dataRange.range} {dataRange?.unit}
													</span>
												</div>
											</div>
										))}
									</div>
								</div>
							)}
							{!isCreate && (
								<div className={` ${isHorizontal ? '' : 'mt-5'} `}>
									<button className="hidden sm:block underline text-lt-beige my-6" onClick={toggleDrawer}>
										Show more details
									</button>
									<div className="flex flex-col gap-2">
										<div className="flex flex-col mt-4 sm:mt-0">
											<label className="font-bold text-sm sm:text-lg" htmlFor="note">
												Notes:
											</label>
											<textarea
												value={newTableData.notes}
												onChange={handleNotesChange}
												maxLength={300}
												id="note"
												className="textarea textarea-bordered w-full  base:w-3/4 max-h-64 dark:bg-lt-dark-popupBackground"
												placeholder="E.g. 11:37 - I've drank 3 glasses of water so far "
											></textarea>
										</div>

										{isNotesUpdated && (
											<Button
												disabled={updateTableNotesIsLoading}
												onClick={() => {
													if (newTableData._id && newTableData.notes)
														handleUpdateNotes(newTableData._id, newTableData.notes);
												}}
												className="w-1/2 text-xs sm:w-1/4  sm:text-base"
												variant="btn-primary-light"
												type="button"
											>
												{updateTableNotesIsLoading ? (
													<span className="loading loading-spinner loading-xs"></span>
												) : (
													'Update Notes'
												)}
											</Button>
										)}
									</div>
								</div>
							)}

							{isCreate && (
								<div className="min-h-[325px] sm:min-h-11">
									<div className="form-control mb-2 w-auto inline-block">
										<label
											data-tooltip-id="create-table-is-public-tooltip"
											data-tooltip-content="If it's public, it will be shown in your journal."
											data-tooltip-place="top"
											className="label cursor-pointer flex justify-start w-auto inline-block"
										>
											<input
												type="checkbox"
												checked={newTableData.isTablePublic}
												onChange={(e) => setNewTableData({ ...newTableData, isTablePublic: e.target.checked })}
												className="checkbox [--chkbg:theme(colors.lt-beige)] [--chkfg:#FAEDCD] dark:[--chkbg:theme(colors.lt-dark-primary-bg)] dark:[--chkfg:#FFFFFF] checked:border-none"
											/>
											<span className="label-text ml-2 text-lt-dark-inputPlaceholder">
												{!newTableData.isTablePublic === false ? 'Make it Private' : 'Make it Public'}
											</span>
											<IconHelpOctagon className="w-6 h-6 text-lt-dark-inputPlaceholder  ml-2" />
											<ReactTooltip id="create-table-is-public-tooltip" />
										</label>
									</div>
									<div className="flex flex-col items-start">
										<Button
											disabled={
												createTableIsLoading ||
												!newTableData.title ||
												!newTableData.dataRanges[0].range ||
												!newTableData.dataRanges[1].range
											}
											variant="btn-primary-light"
											type="button"
											onClick={createTable}
											className="w-full lg:w-5/12"
										>
											{createTableIsLoading ? (
												<span className="loading loading-spinner loading-xs"></span>
											) : (
												'Create Table'
											)}
										</Button>
									</div>
								</div>
							)}
						</div>
						{/* <div className={`${isHorizontal ? 'hidden' : 'sm:hidden'} `}>
							<TableComponent
								isHorizontal={isHorizontal}
								newTableData={newTableData}
								lastSelectedColor={lastSelectedColor}
								componentUsed="tableDetails"
							/>
						</div> */}
					</div>
				)}
			</div>
		</div>
	);
}
