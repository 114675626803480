import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { RootState } from '../../../states/store';
import { useEffect } from 'react';

export const AuthLayout = () => {
	const navigate = useNavigate();
	const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

	useEffect(() => {
		if (isAuthenticated) {
			navigate('/dashboard/my-tables');
		}
	}, [isAuthenticated, navigate]);

	return (
		<>
			<Outlet />
		</>
	);
};
