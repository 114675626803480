import { createSlice } from '@reduxjs/toolkit';

interface AuthState {
	email: string | null;
	isAuthenticated: boolean;
	isLoggingOut: boolean;
}

const initialState: AuthState = {
	email: null,
	isAuthenticated: false,
	isLoggingOut: false
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		loginSuccess(state, action) {
			state.email = action.payload.email;
			state.isAuthenticated = true;
		},
		logoutSuccess(state) {
			state.email = null;
			state.isAuthenticated = false;
		},
		startLogout(state) {
			state.isLoggingOut = true;
		},
		finishLogout(state) {
			state.isLoggingOut = false;
		}
	}
});

export const { loginSuccess, logoutSuccess, startLogout, finishLogout } = authSlice.actions;
export default authSlice.reducer;
