import { Outlet, useNavigate } from 'react-router-dom';
import Sidebar from '../../../components/sidebar/Sidebar';
import { useSelector } from 'react-redux';
import { RootState } from '../../../states/store';
import { useEffect } from 'react';
import BottomMenu from '../../../components/bottomMenu/BottomMenu';
import TopMenu from '../../../components/topMenu/TopMenu';
import { ThemeProvider } from '../../../context/ThemeContext';

export const DashboardLayout = () => {
	const navigate = useNavigate();
	const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

	useEffect(() => {
		if (!isAuthenticated) {
			navigate('/auth/login');
		}
	}, [isAuthenticated, navigate]);

	const isMobile = window.innerWidth < 960;

	return (
		<ThemeProvider>
			<div className="flex max-h-screen overflow-hidden">
				<div className="hidden md:flex">
					<Sidebar />
				</div>
				<div className="flex-1 flex flex-col ">
					<div className="md:hidden">
						<TopMenu />
					</div>
					{isMobile && <Outlet />}
					{!isMobile && (
						<div className="hidden md:flex flex-1">
							<Outlet />
						</div>
					)}
					<div className="md:hidden">
						<BottomMenu />
					</div>
				</div>
			</div>
		</ThemeProvider>
	);
};
