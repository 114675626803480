import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAchievement } from '../../types/achievement.types';

interface AchievementState {
	achievements: IAchievement[];
	myAchievements: IAchievement[];
}

const initialState: AchievementState = {
	achievements: [],
	myAchievements: []
};

const achievementsSlice = createSlice({
	name: 'achievements',
	initialState,
	reducers: {
		getAchievementsSuccess(state, action: PayloadAction<IAchievement[]>) {
			state.achievements = action.payload;
		},
		getMyAchievementsSuccess(state, action: PayloadAction<IAchievement[]>) {
			state.myAchievements = action.payload;
		},
		claimAchievementSuccess(state, action: PayloadAction<IAchievement>) {
			const achievementIndex = state.achievements.findIndex((achievement) => achievement._id === action.payload._id);
			state.achievements[achievementIndex].isClaimed = true;
		}
	}
});

export const { getAchievementsSuccess, claimAchievementSuccess, getMyAchievementsSuccess } = achievementsSlice.actions;
export default achievementsSlice.reducer;
