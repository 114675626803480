import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLogoutMutation } from '../services/api/auth';
import { persistor, resetAction } from '../states/store';
import { finishLogout, startLogout, logoutSuccess } from '../states/auth/authSlice';
import { isCustomErrorResponse } from '../utils/errorUtils';
import toast from 'react-hot-toast';

export const useLogout = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [logoutMutation] = useLogoutMutation();

	const logout = async () => {
		dispatch(startLogout());
		try {
			const response = await logoutMutation({}).unwrap();
			if (response) {
				navigate('/auth/login');
				dispatch(logoutSuccess());
				dispatch(resetAction());
				await persistor.purge();
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		} finally {
			dispatch(finishLogout());
		}
	};

	return logout;
};
