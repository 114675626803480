import { IconComet, IconCrown } from '@tabler/icons-react';
import {
	AchievementLeaderBoard,
	StickerLeaderBoard,
	StreakLeaderBoard,
	useLazyGetLeaderBoardQuery
} from '../../../services/api/social';
import noProfileImg from '../../../assets/no-profile-picture.svg';
import { useEffect, useRef, useState } from 'react';
import placeholder from '../../../assets/no-profile-picture.svg';
import toast from 'react-hot-toast';
import { isCustomErrorResponse } from '../../../utils/errorUtils';
import { Link } from 'react-router-dom';
import { RootState } from '../../../states/store';
import { useSelector } from 'react-redux';
export default function LeaderBoard() {
	const [getLeaderBoardRequest, { isError }] = useLazyGetLeaderBoardQuery();

	const tabs = [
		{
			id: 'streak',
			name: 'Streak'
		},
		{
			id: 'sticker',
			name: 'Sticker'
		},
		{
			id: 'achievement',
			name: 'Achievement'
		}
	];

	const tabsRef = useRef<(HTMLElement | null)[]>([]);
	const [isLoadingLeaderboard, setIsLoadingLeaderboard] = useState(false);
	const [activeTabIndex, setActiveTabIndex] = useState<number | null>(0);
	const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
	const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
	const [firstThreeUsersOfStreakBoard, setFirstThreeUsersOfStreakBoard] = useState<StreakLeaderBoard[] | undefined>(
		undefined
	);
	const [restOfStreakBoard, setRestOfStreakBoard] = useState<StreakLeaderBoard[] | undefined>(undefined);
	const [firstThreeUsersOfStickerBoard, setFirstThreeUsersOfStickerBoard] = useState<StickerLeaderBoard[] | undefined>(
		undefined
	);
	const [restOfStickerBoard, setRestOfStickerBoard] = useState<StickerLeaderBoard[] | undefined>(undefined);
	const [firstThreeUsersOfAchievementBoard, setFirstThreeUsersOfAchievementBoard] = useState<
		AchievementLeaderBoard[] | undefined
	>(undefined);
	const [restOfAchievementBoard, setRestOfAchievementBoard] = useState<AchievementLeaderBoard[] | undefined>(undefined);
	const [leaderBoards, setLeaderBoards] = useState<{
		streakLeaderBoard: StreakLeaderBoard[];
		stickerLeaderBoard: StickerLeaderBoard[];
		achievementsLeaderBoard: AchievementLeaderBoard[];
	} | null>(null);
	const { profile } = useSelector((state: RootState) => state.profile);

	useEffect(() => {
		const fetchLeaderBoard = async () => {
			setIsLoadingLeaderboard(true);
			try {
				const response = await getLeaderBoardRequest().unwrap();
				if (response) {
					setLeaderBoards(response);
				}
			} catch (error) {
				if (isCustomErrorResponse(error)) {
					toast.error(error.data.message);
				} else if (error instanceof Error) {
					toast.error(error.message);
				} else {
					toast.error('An unknown error occurred. Please refresh the page.');
				}
			} finally {
				setIsLoadingLeaderboard(false);
			}
		};
		fetchLeaderBoard();
	}, []);

	useEffect(() => {
		if (activeTabIndex === null) {
			return;
		}

		const setTabPosition = () => {
			const currentTab = tabsRef.current[activeTabIndex] as HTMLElement;
			setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
			setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
		};

		setTabPosition();
	}, [activeTabIndex]);

	useEffect(() => {
		if (leaderBoards) {
			setActiveTabIndex(0);
		}
	}, [leaderBoards]);

	useEffect(() => {
		if (!leaderBoards) {
			return;
		}
		if (isError) {
			toast.error('Failed to fetch leaderboards. Please refresh the page');
			return;
		}
		if (activeTabIndex === 0 && leaderBoards?.streakLeaderBoard) {
			setFirstThreeUsersOfStreakBoard(leaderBoards.streakLeaderBoard.slice(0, 3));
		}
		if (activeTabIndex === 1 && leaderBoards?.stickerLeaderBoard) {
			setFirstThreeUsersOfStickerBoard(leaderBoards.stickerLeaderBoard.slice(0, 3));
		}
		if (activeTabIndex === 2 && leaderBoards?.achievementsLeaderBoard) {
			setFirstThreeUsersOfAchievementBoard(leaderBoards?.achievementsLeaderBoard.slice(0, 3));
		}
	}, [activeTabIndex, leaderBoards]);

	useEffect(() => {
		getRest();
	}, [activeTabIndex, leaderBoards]);

	const getRest = () => {
		if (activeTabIndex === null || !leaderBoards) {
			return;
		}
		if (isError) {
			toast.error('Failed to fetch leaderboards. Please refresh the page');
			return;
		}
		if (activeTabIndex === 0) {
			setRestOfStreakBoard(leaderBoards?.streakLeaderBoard.slice(3));
		}
		if (activeTabIndex === 1) {
			setRestOfStickerBoard(leaderBoards?.stickerLeaderBoard.slice(3));
		}
		if (activeTabIndex === 2) {
			setRestOfAchievementBoard(leaderBoards?.achievementsLeaderBoard.slice(3));
		}
	};

	return (
		<div className="p-4 min-h-72 bg-lt-lightCream dark:bg-lt-dark-popupBorder border-2 border-lt-beige dark:border-lt-dark-secondary-border rounded-default w-10/12">
			<div className="flex">
				<IconComet />
				<h2 className="font-bold text-lg ml-2">LeaderBoard</h2>
			</div>
			{isLoadingLeaderboard ? (
				<div className="w-full min-h-72 flex justify-center items-center">
					<span className="loading loading-spinner loading-xs"></span>
				</div>
			) : (
				<>
					<div className="flew-row flex justify-around h-12 color rounded border-2 border-lt-beige dark:border-lt-dark-primary-border bg-transparent px-2 backdrop-blur-sm">
						<span
							className="absolute bottom-0 top-0 -z-10 flex overflow-hidden rounded py-1 transition-all duration-300"
							style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
						>
							<span className="h-full max-h-10 w-full rounded bg-lt-cream dark:bg-lt-dark-primary-bg" />
						</span>
						{tabs.map((tab, index) => {
							const isActive = activeTabIndex === index;

							return (
								<button
									key={index}
									ref={(el) => {
										tabsRef.current[index] = el;
									}}
									className={`${
										isActive ? `` : `hover:text-neutral-500`
									} my-auto cursor-pointer select-none rounded-full px-4 font-bold text-xs  sm:text-base text-black dark:text-white`}
									onClick={() => setActiveTabIndex(index)}
								>
									{tab.name}
								</button>
							);
						})}
					</div>
					{activeTabIndex === 0 && (
						<>
							{firstThreeUsersOfStreakBoard && firstThreeUsersOfStreakBoard.length >= 3 ? (
								<div className="flex flex-row w-full mt-10">
									<div className="w-1/3 flex flex-col items-center justify-end">
										<Link
											className="flex flex-col items-center hover:text-lt-beige"
											to={`/dashboard/profile/${profile._id !== firstThreeUsersOfStreakBoard[1]._id ? firstThreeUsersOfStreakBoard[1]._id : ''}`}
										>
											{firstThreeUsersOfStreakBoard[1].profileImgUrl ? (
												<img
													src={firstThreeUsersOfStreakBoard[1].profileImgUrl}
													className="w-14 h-14 sm:w-20 sm:h-20 border-2 rounded-full border-[#969696] object-cover"
													alt="Streak board 2. user"
												/>
											) : (
												<img
													src={placeholder}
													className="w-14 h-14 sm:w-20 sm:h-20 border-2 rounded-full border-[#969696]"
													alt="Streak board 2. user placeholder"
												/>
											)}
											<span className="font-bold text-sm sm:text-lg text-center">
												{firstThreeUsersOfStreakBoard[1].username}
											</span>
										</Link>

										<span>{firstThreeUsersOfStreakBoard[1].streakDay.dayCount}</span>
										<div className="w-full h-28 flex items-center justify-center border-2 border-[#969696] bg-[#C5C4C4] rounded font-black text-white text-5xl">
											2
										</div>
									</div>
									<div className="w-1/3 flex flex-col items-center justify-end">
										<IconCrown className="text-[#D4A373]" />
										<Link
											className="flex flex-col items-center hover:text-lt-beige"
											to={`/dashboard/profile/${profile._id !== firstThreeUsersOfStreakBoard[0]._id ? firstThreeUsersOfStreakBoard[0]._id : ''}`}
										>
											{firstThreeUsersOfStreakBoard[0].profileImgUrl ? (
												<img
													src={firstThreeUsersOfStreakBoard[0].profileImgUrl}
													className="w-20 h-20 sm:w-24 sm:h-24 border-2 rounded-full border-[#D4A373] object-cover"
													alt="Streak board 1. user"
												/>
											) : (
												<img
													src={placeholder}
													className="w-18 h-18 sm:w-24 sm:h-24  border-2 rounded-full border-[#9A6431]"
													alt="Streak board 1. user placeholder"
												/>
											)}

											<span className="font-bold text-sm sm:text-lg text-center">
												{firstThreeUsersOfStreakBoard[0].username}
											</span>
										</Link>
										<span>{firstThreeUsersOfStreakBoard[0].streakDay.dayCount}</span>
										<div className="w-full h-44 flex items-center justify-center shadow-lg shadow-[#ffd268b0] border-2 rounded font-black text-white text-7xl border-[#D4A373] bg-[#FFD268]">
											1
										</div>
									</div>
									<div className="w-1/3 flex flex-col items-center justify-end">
										<Link
											className="flex flex-col items-center hover:text-lt-beige"
											to={`/dashboard/profile/${profile._id !== firstThreeUsersOfStreakBoard[2]._id ? firstThreeUsersOfStreakBoard[2]._id : ''}`}
										>
											{firstThreeUsersOfStreakBoard[2].profileImgUrl ? (
												<img
													src={firstThreeUsersOfStreakBoard[2].profileImgUrl}
													className="w-12 h-12 sm:w-16 sm:h-16 border-2 rounded-full border-[#9A6431] object-cover"
													alt="Streak board 3. user"
												/>
											) : (
												<img
													src={placeholder}
													className="w-12 h-12 sm:w-16 sm:h-16  border-2 rounded-full border-[#9A6431]"
													alt="Streak board 3. user placeholder"
												/>
											)}
											<span className="font-bold text-sm sm:text-lg text-center">
												{firstThreeUsersOfStreakBoard[2].username}
											</span>
										</Link>
										<span>{firstThreeUsersOfStreakBoard[2].streakDay.dayCount}</span>
										<div className="w-full h-20 flex items-center justify-center border-2 rounded font-black border-[#9A6431] bg-[#C27E3E] text-white text-4xl">
											3
										</div>
									</div>
								</div>
							) : (
								<div className="w-full h-20 flex items-center justify-center">
									<h6 className="font-bold text-base">It will be displayed starting from 3 users.</h6>
								</div>
							)}
							<div className="p-2">
								{restOfStreakBoard && restOfStreakBoard.length >= 3 && (
									<ul>
										{restOfStreakBoard.map((leaderBoard: StreakLeaderBoard, index: number) => (
											<li className="flex justify-between py-2" key={index}>
												<div className="flex items-center">
													<span className="text-lt-beige font-bold w-6">{index + 4}</span>
													<Link
														className="flex items-center hover:text-lt-beige"
														to={`/dashboard/profile/${profile._id !== leaderBoard._id ? leaderBoard._id : ''}`}
													>
														<img
															src={leaderBoard.profileImgUrl ?? noProfileImg}
															className="w-8 h-8 sm:w-10 sm:h-10 rounded-full ml-0 md:ml-4 object-cover"
															alt={leaderBoard.username}
														/>
														<h6 className="font-bold text-sm sm:text-base ml-2">{leaderBoard.username}</h6>
													</Link>
												</div>

												<h6 className="font-bold flex items-center">{leaderBoard.streakDay.dayCount}</h6>
											</li>
										))}
									</ul>
								)}
							</div>
						</>
					)}
					{activeTabIndex === 1 && (
						<>
							{firstThreeUsersOfStickerBoard && firstThreeUsersOfStickerBoard.length >= 3 ? (
								<div className="flex flex-row w-full mt-10">
									<div className="w-1/3 flex flex-col items-center justify-end">
										<Link
											className="flex flex-col items-center hover:text-lt-beige"
											to={`/dashboard/profile/${profile._id !== firstThreeUsersOfStickerBoard[1]._id ? firstThreeUsersOfStickerBoard[1]._id : ''}`}
										>
											{firstThreeUsersOfStickerBoard[1].profileImgUrl ? (
												<img
													src={firstThreeUsersOfStickerBoard[1].profileImgUrl}
													className="w-14 h-14 sm:w-20 sm:h-20 border-2 rounded-full border-[#969696] object-cover"
													alt="Sticker board 2. user"
												/>
											) : (
												<img
													src={placeholder}
													className="w-14 h-14 sm:w-20 sm:h-20 border-2 rounded-full border-[#969696]"
													alt="Sticker board 2. user placeholder"
												/>
											)}
											<span className="font-bold text-sm sm:text-lg">{firstThreeUsersOfStickerBoard[1].username}</span>
										</Link>
										<span>{firstThreeUsersOfStickerBoard[1].stickersCount}</span>
										<div className="w-full h-28 flex items-center justify-center border-2 border-[#969696] bg-[#C5C4C4] rounded font-black text-white text-5xl">
											2
										</div>
									</div>
									<div className="w-1/3 flex flex-col items-center justify-end">
										<IconCrown className="text-[#D4A373]" />
										<Link
											className="flex flex-col items-center hover:text-lt-beige"
											to={`/dashboard/profile/${profile._id !== firstThreeUsersOfStickerBoard[0]._id ? firstThreeUsersOfStickerBoard[0]._id : ''}`}
										>
											{firstThreeUsersOfStickerBoard[0].profileImgUrl ? (
												<img
													src={firstThreeUsersOfStickerBoard[0].profileImgUrl}
													className="w-20 h-20 sm:w-24 sm:h-24 border-2 rounded-full border-[#D4A373] object-cover"
													alt="Sticker board 1. user"
												/>
											) : (
												<img
													src={placeholder}
													className="w-20 h-20 sm:w-24 sm:h-24 border-2 rounded-full border-[#9A6431]"
													alt="Sticker board 1. user placeholder"
												/>
											)}

											<span className="font-bold text-sm sm:text-lg">{firstThreeUsersOfStickerBoard[0].username}</span>
										</Link>
										<span>{firstThreeUsersOfStickerBoard[0].stickersCount}</span>
										<div className="w-full h-44 flex items-center justify-center shadow-lg shadow-[#ffd268b0] border-2 rounded font-black text-white text-7xl border-[#D4A373] bg-[#FFD268]">
											1
										</div>
									</div>
									<div className="w-1/3 flex flex-col items-center justify-end">
										<Link
											className="flex flex-col items-center hover:text-lt-beige"
											to={`/dashboard/profile/${profile._id !== firstThreeUsersOfStickerBoard[2]._id ? firstThreeUsersOfStickerBoard[2]._id : ''}`}
										>
											{firstThreeUsersOfStickerBoard[2].profileImgUrl ? (
												<img
													src={firstThreeUsersOfStickerBoard[2].profileImgUrl}
													className="w-12 h-12 sm:w-16 sm:h-16  border-2 rounded-full border-[#9A6431] object-cover"
													alt="Sticker board 3. user"
												/>
											) : (
												<img
													src={placeholder}
													className="w-12 h-12 sm:w-16 sm:h-16  border-2 rounded-full border-[#9A6431]"
													alt="Sticker board 3. user placeholder"
												/>
											)}
											<span className="font-bold text-sm sm:text-lg">{firstThreeUsersOfStickerBoard[2].username}</span>
										</Link>
										<span>{firstThreeUsersOfStickerBoard[2].stickersCount}</span>
										<div className="w-full h-20 flex items-center justify-center border-2 rounded font-black border-[#9A6431] bg-[#C27E3E] text-white text-4xl">
											3
										</div>
									</div>
								</div>
							) : (
								<div className="w-full h-20 flex items-center justify-center">
									<h6 className="font-bold text-base">It will be displayed starting from 3 users.</h6>
								</div>
							)}
							<div className="p-2">
								{restOfStickerBoard && restOfStickerBoard.length >= 3 && (
									<ul>
										{restOfStickerBoard.map((leaderBoard: StickerLeaderBoard, index: number) => (
											<li className="flex justify-between py-2" key={index}>
												<div className="flex items-center">
													<span className="text-lt-beige font-bold w-6">{index + 4}</span>
													<Link
														className="flex items-center hover:text-lt-beige"
														to={`/dashboard/profile/${profile._id !== leaderBoard._id ? leaderBoard._id : ''}`}
													>
														<img
															src={leaderBoard.profileImgUrl ?? noProfileImg}
															className="w-8 h-8 sm:w-10 sm:h-10 rounded-full ml-0 md:ml-4 object-cover"
															alt={leaderBoard.username}
														/>
														<h6 className="font-bold text-sm sm:text-base ml-2">{leaderBoard.username}</h6>
													</Link>
												</div>

												<h6 className="font-bold">{leaderBoard.stickersCount}</h6>
											</li>
										))}
									</ul>
								)}
							</div>
						</>
					)}
					{activeTabIndex === 2 && (
						<>
							{firstThreeUsersOfAchievementBoard && firstThreeUsersOfAchievementBoard.length >= 3 ? (
								<div className="flex flex-row w-full mt-10">
									<div className="w-1/3 flex flex-col items-center justify-end">
										<Link
											className="flex flex-col items-center hover:text-lt-beige"
											to={`/dashboard/profile/${profile._id !== firstThreeUsersOfAchievementBoard[1]._id ? firstThreeUsersOfAchievementBoard[1]._id : ''}`}
										>
											{firstThreeUsersOfAchievementBoard[1].profileImgUrl ? (
												<img
													src={firstThreeUsersOfAchievementBoard[1].profileImgUrl}
													className="w-14 h-14 sm:w-20 sm:h-20 border-2 rounded-full border-[#969696] object-cover"
													alt="Achievement board 2. user"
												/>
											) : (
												<img
													src={placeholder}
													className="w-14 h-14 sm:w-20 sm:h-20 border-2 rounded-full border-[#969696]"
													alt="Achievement board 2. user placeholder"
												/>
											)}
											<span className="font-bold text-sm sm:text-lg">
												{firstThreeUsersOfAchievementBoard[1].username}
											</span>
										</Link>
										<span>{firstThreeUsersOfAchievementBoard[1].achievementsCount}</span>
										<div className="w-full h-28 flex items-center justify-center border-2 border-[#969696] bg-[#C5C4C4] rounded font-black text-white text-5xl">
											2
										</div>
									</div>
									<div className="w-1/3 flex flex-col items-center justify-end">
										<IconCrown className="text-[#D4A373]" />
										<Link
											className="flex flex-col items-center hover:text-lt-beige"
											to={`/dashboard/profile/${profile._id !== firstThreeUsersOfAchievementBoard[0]._id ? firstThreeUsersOfAchievementBoard[0]._id : ''}`}
										>
											{firstThreeUsersOfAchievementBoard[0].profileImgUrl ? (
												<img
													src={firstThreeUsersOfAchievementBoard[0].profileImgUrl}
													className="w-20 h-20 sm:w-24 sm:h-24 border-2 rounded-full border-[#D4A373] object-cover"
													alt="Achievement board 1. user"
												/>
											) : (
												<img
													src={placeholder}
													className="w-20 h-20 sm:w-24 sm:h-24  border-2 rounded-full border-[#9A6431]"
													alt="Achievement board 1. user placeholder"
												/>
											)}

											<span className="font-bold text-sm sm:text-lg">
												{firstThreeUsersOfAchievementBoard[0].username}
											</span>
										</Link>
										<span>{firstThreeUsersOfAchievementBoard[0].achievementsCount}</span>
										<div
											className="w-full h-44 flex items-center justify-center shadow-lg shadow-[#ffd268b0] border-2 rounded font-black text-white text-7xl border-[
								#D4A373] bg-[#FFD268]"
										>
											1
										</div>
									</div>
									<div className="w-1/3 flex flex-col items-center justify-end">
										<Link
											className="flex flex-col items-center hover:text-lt-beige"
											to={`/dashboard/profile/${profile._id !== firstThreeUsersOfAchievementBoard[2]._id ? firstThreeUsersOfAchievementBoard[2]._id : ''}`}
										>
											{firstThreeUsersOfAchievementBoard[2].profileImgUrl ? (
												<img
													src={firstThreeUsersOfAchievementBoard[2].profileImgUrl}
													className="w-12 h-12 sm:w-16 sm:h-16  border-2 rounded-full border-[#9A6431] object-cover"
													alt="Achievement board 3. user"
												/>
											) : (
												<img
													src={placeholder}
													className="w-12 h-12 sm:w-16 sm:h-16  border-2 rounded-full border-[#9A6431]"
													alt="Achievement board 3. user placeholder"
												/>
											)}
											<span className="font-bold text-sm sm:text-lg">
												{firstThreeUsersOfAchievementBoard[2].username}
											</span>
										</Link>
										<span>{firstThreeUsersOfAchievementBoard[2].achievementsCount}</span>
										<div className="w-full h-20 flex items-center justify-center border-2 rounded font-black border-[#9A6431] bg-[#C27E3E] text-white text-4xl">
											3
										</div>
									</div>
								</div>
							) : (
								<div className="w-full h-20 flex items-center justify-center">
									<h6 className="font-bold text-base">It will be displayed starting from 3 users.</h6>
								</div>
							)}
							<div className="p-2">
								{restOfAchievementBoard && restOfAchievementBoard.length >= 3 && (
									<ul>
										{restOfAchievementBoard.map((leaderBoard: AchievementLeaderBoard, index: number) => (
											<li className="flex justify-between py-2" key={index}>
												<div className="flex items-center">
													<span className="text-lt-beige font-bold w-6">{index + 4}</span>
													<Link
														className="flex items-center hover:text-lt-beige"
														to={`/dashboard/profile/${profile._id !== leaderBoard._id ? leaderBoard._id : ''}`}
													>
														<img
															src={leaderBoard.profileImgUrl ?? noProfileImg}
															className="w-8 h-8 sm:w-10 sm:h-10 rounded-full ml-0 md:ml-4 object-cover"
															alt={leaderBoard.username}
														/>
														<h6 className="font-bold text-sm sm:text-base ml-2">{leaderBoard.username}</h6>
													</Link>
												</div>

												<h6 className="font-bold">{leaderBoard.achievementsCount}</h6>
											</li>
										))}
									</ul>
								)}
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
}
