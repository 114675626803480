import { CustomErrorResponse } from "../types/error.types"; // Doğru yolu kullanın

export function isCustomErrorResponse(
  error: unknown
): error is CustomErrorResponse {
  return (
    typeof error === "object" &&
    error !== null &&
    "data" in error &&
    typeof (error as CustomErrorResponse).data.message === "string"
  );
}
