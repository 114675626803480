import { useState } from 'react';
import { Transition } from '@headlessui/react';
import { Element } from 'react-scroll';

const faqData = [
	{
		question: 'What is the main purpose of this app?',
		answer:
			'The app allows users to track various aspects of their daily lives, such as mood, water intake, and step count, using customizable tables where each table represents a year and each box within the table represents a day. Users can assign colors to different keywords (e.g., "happy," "stressed," "angry" for a daily mood table) and select the appropriate data for each day. As time passes, the table becomes more colorful and detailed. In addition to this, users can earn achievements and coins by completing different tasks within the app. They also gain streaks, and for each day they log in, they earn coins based on their current streak count. These coins can be used to purchase stickers from the shop, which they can use to decorate their virtual journal in the "My Journal" section. Moreover, users can connect with friends, view their journals, stickers, and achievements, and keep track of their activities. They can also compete on the leaderboard in various categories, trying to reach the top 10.'
	},
	{
		question: 'Can everyone see the tables I create?',
		answer:
			'No, your tables are only visible to people who follow you. However, if you prefer to keep them private, you can set your table to private so that only you can see it.'
	},
	{
		question: 'What can I do with the in-app money?',
		answer: 'You can use the coins you earn in the app to purchase various stickers from the shop.'
	},
	{
		question: 'What can I do with the stickers I purchase?',
		answer:
			'You can use the stickers you purchase to decorate your virtual journal on the My Journal page, making your tables and journal more visually appealing.'
	},
	{
		question: 'How does the virtual journal work?',
		answer:
			'The virtual journal is where you can display your tables and stickers. It’s a creative space where you can organize your activity tracking in a visually appealing way. Friends who follow you can view your journal.'
	},
	{
		question: 'What are achievements and leaderboards?',
		answer:
			'Achievements are milestones you can unlock as you use the app, such as logging activities consistently or reaching specific goals. Leaderboards show how you rank compared to your friends and other users in different activities.'
	},
	{
		question: 'What can I see from the people I follow?',
		answer:
			'You can view the achievements, stickers, streak count, recent activities, and virtual journals of the users you follow.'
	},
	{
		question: 'Can I use the app for free?',
		answer:
			'Absolutely! In the free version, you won’t be able to earn coins through daily streak bonuses and can have a maximum of one table. Other than that, you have access to all the features available in the pro version.'
	},
	{
		question: 'Can I access my journal and tables from different devices?',
		answer:
			'Yes, your data is stored securely, and you can access your journal and tables from any device by logging into your account.'
	},
	{
		question: 'Is my data secure?',
		answer:
			'Absolutely. We take data security seriously and ensure that all your information is stored securely and is accessible only by you.'
	}
];

export default function FAQ() {
	const [openIndex, setOpenIndex] = useState<number | null>(null);

	const handleClick = (index: number) => {
		setOpenIndex(openIndex === index ? null : index);
	};
	return (
		<Element name="faq" className="px-1 sm:px-0 flex flex-col text-center items-center gap-5 mt-20 px-2 w-full">
			<header className="font-bold text-3xl sm:text-5xl">FAQ</header>
			<h2 className="sm:font-semibold sm:text-xl">Answers to all the questions you got on your mind</h2>
			<div className="  mt-8 w-full md:w-3/4 base:w-1/2">
				{faqData.map((item, index) => (
					<div key={index} className="mb-4 border border-lt-beige rounded-md bg-[#FAEDCD] ">
						<button
							onClick={() => handleClick(index)}
							className="w-full text-left py-3 px-4 bg-[#FAEDCD] hover:bg-lt-beige focus:outline-none rounded-md"
						>
							<div className="flex justify-between items-center ">
								<span className="font-bold text-[#8E4600] text-lg sm:text-xl">{item.question}</span>
								<span>{openIndex === index ? '-' : '+'}</span>
							</div>
						</button>
						<Transition
							show={openIndex === index}
							enter="transition ease-out duration-300"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-200"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95"
						>
							<div className="px-4 pb-3 text-[#8E4600] bg-[#FAEDCD] rounded-md text-start">{item.answer}</div>
						</Transition>
					</div>
				))}
			</div>
		</Element>
	);
}
