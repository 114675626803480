import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../states/store';
import { useBuyShopItemMutation, useLazyGetShopItemsQuery } from '../../../services/api/shop';
import { IShopItem } from '../../../types/shop.types';
import { useEffect, useRef, useState } from 'react';
import { getShopItemsSuccess } from '../../../states/shop/shopItemSlice';
import { isCustomErrorResponse } from '../../../utils/errorUtils';
import toast from 'react-hot-toast';
import { IconCaretDown, IconChevronLeft, IconChevronRight, IconCoins, IconSticker } from '@tabler/icons-react';
import { achievementEarnedSuccess, buyShopItemSuccess } from '../../../states/profile/profileSlice';
import { IAchievement } from '../../../types/achievement.types';
import AchievementToast from '../../../components/profile/achievements/AchievementToast';
import CustomModule from '../../../components/customModule/customModule';
import Button from '../../../components/button/button';
import './Shop.css';
import { Helmet } from 'react-helmet';

export default function Shop() {
	const dispatch = useDispatch();
	const [getShopItemsRequest] = useLazyGetShopItemsQuery();
	const [getShopItemsIsLoading, setGetShopItemsIsLoading] = useState(false);
	const { profile } = useSelector((state: RootState) => state.profile);
	const { shopItems, loadedPages, pagination } = useSelector((state: RootState) => state.shopItems);
	const [currentPage, setCurrentPage] = useState(1);
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const [buyShopItemRequest, { isLoading: buyShopItemIsLoading }] = useBuyShopItemMutation();
	const [shopItem, setShopItem] = useState<IShopItem>();

	useEffect(() => {
		if (!loadedPages.includes(currentPage)) {
			const fetchShopItems = async () => {
				setGetShopItemsIsLoading(true);
				try {
					const response = await getShopItemsRequest({ page: currentPage, limit: 10 }).unwrap();
					if (response) {
						dispatch(getShopItemsSuccess({ items: response.data, pagination: response.pagination }));
					}
				} catch (error) {
					if (isCustomErrorResponse(error)) {
						toast.error(error.data.message);
					} else if (error instanceof Error) {
						toast.error(error.message);
					} else {
						toast.error('An unknown error occurred. Please try again.');
					}
				} finally {
					setGetShopItemsIsLoading(false);
				}
			};

			fetchShopItems();
		}
	}, [currentPage, dispatch, loadedPages]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent | TouchEvent | PointerEvent | KeyboardEvent | FocusEvent | Event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setIsOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handlePageSelect = (page: number) => {
		setCurrentPage(page);
		setIsOpen(false);
	};

	const handleDropdownToggle = () => {
		setIsOpen((prev) => !prev);
	};

	const isItemClaimed = (item: IShopItem): boolean => {
		return profile.stickers.includes(item._id);
	};

	const openBuyModal = (shopItem: IShopItem) => {
		(document.getElementById('shop-ays-modal') as HTMLDialogElement).showModal();
		setShopItem(shopItem);
	};

	const buyItem = async () => {
		try {
			if (!shopItem) return;
			const response = await buyShopItemRequest({ itemId: shopItem._id }).unwrap();
			if (response) {
				dispatch(
					buyShopItemSuccess({
						itemId: shopItem._id,
						coin: shopItem.cost
					})
				);
				if (response.achievements.length > 0) {
					dispatch(achievementEarnedSuccess(response.achievements));
					response.achievements.forEach((achievement: IAchievement) => {
						toast(
							<AchievementToast
								title={achievement.title}
								description={achievement.description}
								threshold={achievement.threshold}
							/>
						);
					});
				}
				toast.success(response.message);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		} finally {
			(document.getElementById('shop-ays-modal') as HTMLDialogElement).close();
		}
	};

	const getPaginatedItems = () => {
		return shopItems[currentPage] || [];
	};

	return (
		<div className="relative my-16 overflow-y-auto md:m-4 bg-lt-cream border-2 border-lt-beige dark:bg-lt-dark-secondary-bg dark:border-lt-dark-secondary-border w-full rounded h-screen flex flex-col">
			<Helmet>
				<title>Shop</title>
				<meta name="description" content="Shop for stickers to decorate your journal." />
			</Helmet>
			<div className="md:relative p-4 fixed sm:top-0 top-12 right-0 left-0 bg-lt-cream dark:bg-lt-dark-secondary-bg w-full z-10">
				<h1 className="text-4xl font-bold">Shop</h1>
			</div>
			{getShopItemsIsLoading ? (
				<div className="w-full flex justify-center h-screen">
					<span className="loading loading-spinner loading-lg text-center"></span>
				</div>
			) : (
				<>
					<div className="flex-grow  p-5 my-10 md:my-0">
						<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
							{getPaginatedItems().map((item, index) => (
								<div
									key={index}
									className="w-full h-auto flex flex-col justify-between gap-4 border-2 border-lt-beige p-2 rounded min-h-[250px]"
								>
									<CustomModule
										id="shop-ays-modal"
										title="Are you sure you want to buy this item?"
										description={`Your updated balance will be ${shopItem?.cost && profile.appMoney - shopItem.cost} coins. Do you want to proceed?`}
										buttonText="Buy"
										onButtonClick={() => {
											buyItem();
										}}
										isLoading={buyShopItemIsLoading}
									/>
									<div className="flex flex-row items-center gap-2">
										<IconSticker />
										<h2 className="font-semibold">{item.title}</h2>
									</div>
									<div className="relative flex w-full h-full items-center justify-center">
										<div className="sticker-background w-36 h-44 flex items-center justify-center">
											<img
												src={item.stickerUrl}
												className="max-w-[135px] max-h-[135px] rounded object-cover"
												alt="Item Photo"
											/>
											<div className="sticker-corner top-right"></div>
											<div className="sticker-corner bottom-left"></div>
										</div>
									</div>
									<div className="flex flex-row items-center justify-between">
										<div className="flex flex-row text-lt-light-primary-bg">
											<IconCoins />
											<p>{item.cost}</p>
										</div>
										<Button
											disabled={isItemClaimed(item) || profile.appMoney < item.cost}
											type="button"
											variant="btn-primary-light"
											className="btn-sm text-sm border-none rounded-md"
											onClick={() => openBuyModal(item)}
										>
											{isItemClaimed(item) ? 'Claimed' : 'Buy'}
										</Button>
									</div>
								</div>
							))}
						</div>
					</div>
				</>
			)}
			<div className="relative flex-none p-4 z-20">
				<div className=" flex justify-between rounded-md space-x-2 fixed md:relative bottom-14 md:bottom-0 left-1/2 transform -translate-x-1/2 bg-lt-cream dark:bg-lt-dark-secondary-bg w-full md:w-auto">
					<button
						disabled={getShopItemsIsLoading || currentPage === 1}
						onClick={() => {
							if (currentPage === 1) return;
							setCurrentPage(currentPage - 1);
						}}
						className="btn bg-transparent border-none"
					>
						<IconChevronLeft />
					</button>
					<div ref={dropdownRef} className="relative mx-4">
						<button disabled={getShopItemsIsLoading} className="btn m-1 bg-transparent" onClick={handleDropdownToggle}>
							{currentPage} of {pagination.totalPages}
							<IconCaretDown className="w-4 h-4" />
						</button>
						{isOpen && (
							<ul className="menu dropdown-content rounded-md z-[1] bg-lt-lightCream dark:bg-lt-dark-popupBackground w-28 p-0 shadow absolute bottom-full left-0 max-h-60 overflow-y-auto">
								{Array(pagination.totalPages)
									.fill(null)
									.map((_, i) => (
										<li key={i}>
											<button
												className="h-10 rounded-sm border border-lt-beige dark:border-lt-dark-popupBorder w-full"
												onClick={() => handlePageSelect(i + 1)}
												value={i + 1}
											>
												{i + 1}
											</button>
										</li>
									))}
							</ul>
						)}
					</div>

					<button
						disabled={getShopItemsIsLoading || currentPage === pagination.totalPages}
						onClick={() => {
							if (currentPage === pagination.totalPages) return;
							setCurrentPage(currentPage + 1);
						}}
						className="btn bg-transparent border-none"
					>
						<IconChevronRight />
					</button>
				</div>
			</div>
		</div>
	);
}
