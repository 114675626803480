import { createApi } from '@reduxjs/toolkit/query/react';
import { IShopItem } from '../../types/shop.types';
import baseQueryWithReauth from './customBaseQuery';
import { IAchievement } from '../../types/achievement.types';

interface ShopItemsResponse {
	data: IShopItem[];
	pagination: {
		page: number;
		totalPages: number;
		totalShopItems: number;
	};
}

export interface BuyShopItemRequest {
	itemId: string;
}

export interface BuyShopItemResponse {
	message: string;
	achievements: IAchievement[];
}

export const shopItemsApi = createApi({
	reducerPath: 'shopItemsApi',
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getShopItems: builder.query<ShopItemsResponse, { page?: number; limit?: number }>({
			query: ({ page, limit }) => `api/shop/get-items?page=${page}&limit=${limit}`
		}),
		buyShopItem: builder.mutation<BuyShopItemResponse, BuyShopItemRequest>({
			query: (body) => ({
				url: `api/shop/buy-item`,
				method: 'POST',
				body
			})
		})
	})
});

export const { useLazyGetShopItemsQuery, useBuyShopItemMutation } = shopItemsApi;
