import { Link } from 'react-router-dom';

export default function Footer() {
	return (
		<footer className="flex justify-center h-[350px] gap-2 bg-gradient-to-b w-full mt-10 from-lt-lightCream to-[#8E4600] dark:from-[#121212] dark:to-lt-dark-popupBackground p-2 sm:p-4 md:p-8">
			<div className="flex flex-col justify-between max-w-[1000px]">
				<div className="flex justify-center mx-auto gap-4 sm:gap-40  pt-6">
					<div>
						<h2 className="text-[#8E4600] font-bold text-lg sm:text-2xl">Social</h2>
						<div className="mt-4">
							<ul className="flex gap-2 flex-col text-sm sm:text-xl text-[#333333] dark:text-white font-semibold">
								<li>
									<a className="hover:underline" href="https://twitter.com/Kaanmrttt">
										Twitter
									</a>
								</li>
								<li>
									<a className="hover:underline" href="https://www.linkedin.com/in/kaan-mert-7b2809208/">
										Linkedin
									</a>
								</li>
								<li>
									<a className="hover:underline" href="https://www.instagram.com/kaanmerttt/">
										Instagram
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div>
						<h2 className="text-[#8E4600] font-bold text-lg sm:text-2xl">Learn More</h2>
						<div className="mt-4">
							<ul className="text-[#333333] dark:text-white text-sm sm:text-xl font-semibold">
								<li>
									<a className="hover:underline" href="mailto:dev@lifetracker.live">
										Contact
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div>
						<h2 className="text-[#8E4600] font-bold text-lg sm:text-2xl">Legal</h2>
						<div className="mt-4">
							<ul className="flex gap-2 flex-col text-sm sm:text-xl text-[#333333] dark:text-white font-semibold">
								<li>
									<Link to="/privacy-policy" className="hover:underline">
										Privacy Policy
									</Link>
								</li>
								<li>
									<Link to="/terms-and-conditions" className="hover:underline">
										Terms & Conditions
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="flex items-center justify-between border-t pt-5 border-[#C8A165]">
					<a className="text-[#C8A165] text-xl sm:text-3xl font-bold" href="">
						Life Tracker
					</a>
					<h6 className="text-[#333333] dark:text-white text-2xs sm:text-xs md:text-base  font-semibold">
						{' '}
						© 2024 Life Tracker. All rights reserved.
					</h6>
				</div>
			</div>
		</footer>
	);
}
