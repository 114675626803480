import { useDispatch, useSelector } from 'react-redux';
import { useClaimAchievementMutation, useGetAchievementsQuery } from '../../services/api/achievement';
import { RootState } from '../../states/store';
import { useEffect, useState } from 'react';
import { getAchievementsSuccess } from '../../states/achievements/achievementsSlice';
import { IAchievement } from '../../types/achievement.types';
import CustomModule from '../../components/customModule/customModule';
import { IconCoins, IconLicense } from '@tabler/icons-react';
import Button from '../../components/button/button';
import { claimAchievementSuccessProfile } from '../../states/profile/profileSlice';
import toast from 'react-hot-toast';
import { isCustomErrorResponse } from '../../utils/errorUtils';
import { Helmet } from 'react-helmet';

export default function Achievements() {
	const dispatch = useDispatch();
	const { achievements } = useSelector((state: RootState) => state.achievements);
	const { profile } = useSelector((state: RootState) => state.profile);
	const { data: achievementsData, isLoading } = useGetAchievementsQuery();
	const [claimAchievementRequest, { isLoading: claimAchievementIsLoading }] = useClaimAchievementMutation();
	const [selectedAchievement, setSelectedAchievement] = useState<IAchievement>();

	useEffect(() => {
		if (achievements.length === 0 || !achievements) {
			if (achievementsData && achievementsData.achievements) {
				dispatch(getAchievementsSuccess(achievementsData.achievements));
			}
		}
	}, [achievementsData]);

	const groupAchievementsByType = (achievements: IAchievement[]) => {
		const firstWord = achievements.map((ach) => ach.type.split('_')[0]);
		const groupedAchievements = firstWord.reduce(
			(acc, curr) => {
				if (!acc[curr]) {
					acc[curr] = [];
				}
				return acc;
			},
			{} as Record<string, IAchievement[]>
		);

		achievements.forEach((ach) => {
			const type = ach.type.split('_')[0];
			groupedAchievements[type].push(ach);
		});

		return groupedAchievements;
	};

	const claimAchievement = async () => {
		try {
			if (!selectedAchievement) return;
			const response = await claimAchievementRequest({ achievementId: selectedAchievement._id }).unwrap();

			if (response) {
				dispatch(
					claimAchievementSuccessProfile({
						achId: selectedAchievement._id,
						reward: selectedAchievement.reward
					})
				);
				toast.success(response.message);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please refresh the page.');
			}
		} finally {
			(document.getElementById('claim-modal') as HTMLDialogElement).close();
		}
	};

	const openClaimModal = (achievement: IAchievement) => {
		(document.getElementById('claim-modal') as HTMLDialogElement).showModal();
		setSelectedAchievement(achievement);
	};
	const groupedAchievements = groupAchievementsByType(achievements);

	const formatGroupName = (groupName: string) => {
		return groupName.split('_').join(' ') + ' RELATED ACHIEVEMENTS';
	};

	return (
		<div className="md:m-4 my-16 overflow-y-auto relative h-screen bg-lt-cream border-2 border-lt-beige dark:bg-lt-dark-secondary-bg dark:border-lt-dark-secondary-border w-full rounded">
			<Helmet>
				<title>Achievements</title>
				<meta
					name="description"
					content="Here you can view all the achievements. You can claim your rewards by clicking on the 'Claim' button."
				/>
			</Helmet>
			<div className="fixed top-12 sm:top-0 left-0 right-0 md:relative p-4 bg-lt-cream dark:bg-lt-dark-secondary-bg w-full">
				<h1 className="text-4xl font-bold">Achievements</h1>
			</div>
			{isLoading ? (
				<div className="w-full flex justify-center h-screen">
					<span className="loading loading-spinner loading-lg text-center"></span>
				</div>
			) : (
				<div className="flex flex-row flex-wrap gap-5 gap-y-10 w-fit p-10 my-10 md:my-0 lg:p-5">
					{Object.entries(groupedAchievements).map(([type, achievements]) => (
						<div key={type}>
							<h2 className="text-xl font-bold">{formatGroupName(type)}</h2>
							<div className="w-full flex flex-row flex-wrap gap-4 mt-2 max-w-[500px]">
								{achievements.map((achievement: IAchievement, index: number) => (
									<div
										key={index}
										className={`border-2 rounded p-3 dark:border-lt-dark-primary-border ${
											profile.achievements.find((a) => a._id === achievement._id && a.isClaimed)
												? 'border-lt-dark-secondary-disabled-text '
												: 'border-lt-beige'
										} w-full flex flex-row justify-between`}
									>
										<CustomModule
											id="claim-modal"
											title={`Claim ${selectedAchievement?.title}`}
											description={`Your balance will be updated with ${selectedAchievement?.reward} coins.`}
											buttonText="Claim"
											onButtonClick={() => {
												claimAchievement();
											}}
											isLoading={claimAchievementIsLoading}
										/>
										<div className="flex flex-row gap-3">
											<IconLicense
												className={
													profile.achievements.find((a) => a._id === achievement._id && a.isClaimed)
														? 'text-lt-dark-primary-disabled-text'
														: ''
												}
											/>
											<div className="flex flex-col">
												<span
													className={
														profile.achievements.find((a) => a._id === achievement._id && a.isClaimed)
															? 'font-bold line-through text-lt-dark-primary-disabled-text'
															: 'font-bold'
													}
												>
													{achievement.title}
												</span>
												<span
													className={
														profile.achievements.find((a) => a._id === achievement._id && a.isClaimed)
															? 'font-normal line-through text-lt-dark-primary-disabled-text'
															: 'font-normal'
													}
												>
													{achievement.description}
												</span>
											</div>
										</div>

										<span className="flex flex-row items-center gap-2">
											<div className="flex flex-row text-lt-light-primary-bg">
												<IconCoins
													className={
														profile.achievements.find((a) => a._id === achievement._id && a.isClaimed)
															? 'text-lt-dark-primary-disabled-text'
															: ''
													}
												/>
												<span
													className={
														profile.achievements.find((a) => a._id === achievement._id && a.isClaimed)
															? 'font-bold line-through text-lt-dark-primary-disabled-text'
															: 'font-bold'
													}
												>
													{achievement.reward}
												</span>
											</div>
											{profile.achievements.find((a) => a._id === achievement._id && a.isClaimed !== undefined) ? (
												<Button
													variant="btn-primary-light"
													className="text-sm rounded-md border-none btn-sm"
													type="button"
													onClick={() => openClaimModal(achievement)}
													disabled={
														profile.achievements.find((a) => a._id === achievement._id && a.isClaimed) ? true : false
													}
												>
													{profile.achievements.find((a) => a._id === achievement._id && a.isClaimed)
														? 'Claimed'
														: 'Claim'}
												</Button>
											) : (
												<></>
											)}
										</span>
									</div>
								))}
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
}
