export const ItemTypes = {
	STICKER: 'sticker'
};

export enum Location {
	TOP = 'TOP',
	MIDDLE_LEFT = 'MIDDLE_LEFT',
	MIDDLE_RIGHT = 'MIDDLE_RIGHT',
	BOTTOM_LEFT = 'BOTTOM_LEFT',
	BOTTOM_RIGHT = 'BOTTOM_RIGHT'
}

export interface JournalPage {
	_id?: string;
	pageNumber: number;
	stickerLocations: StickerLocations[];
}

export interface StickerLocations {
	location: Location;
	stickerId: string;
	pageNumber: number;
	stickerUrl: string;
}
