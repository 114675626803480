import StreakDisplay from './StreakDisplay';
import { useSelector } from 'react-redux';
import { RootState } from '../../../states/store';
import { useLazyGetFriendsStreakDayQuery } from '../../../services/api/profile';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IStreakDay } from '../../../types/profile.types';
import { isCustomErrorResponse } from '../../../utils/errorUtils';
import toast from 'react-hot-toast';

export default function Streaks({ isFollowing }: { isFollowing?: boolean }) {
	const { userId } = useParams<string>();
	const { profile } = useSelector((state: RootState) => state.profile);
	const [getFriendsStreakDayRequest] = useLazyGetFriendsStreakDayQuery();
	const [friendsStreakDay, setFriendsStreakDay] = useState<IStreakDay>();

	useEffect(() => {
		const getFriendsStreakDay = async () => {
			if (isFollowing) {
				if (!userId) return;
				try {
					const response = await getFriendsStreakDayRequest(userId).unwrap();
					if (response) setFriendsStreakDay(response.streakDay);
				} catch (error) {
					if (isCustomErrorResponse(error)) {
						toast.error(error.data.message);
					} else if (error instanceof Error) {
						toast.error(error.message);
					} else {
						toast.error('An unknown error occurred. Please refresh the page.');
					}
				}
			}
		};

		getFriendsStreakDay();
	}, [isFollowing]);

	return (
		<div className="flex items-end">
			<StreakDisplay streakCount={profile.streakDay.dayCount} />
			<span className="flex gap-1">
				<strong>{isFollowing ? (friendsStreakDay?.dayCount ?? 0) : profile.streakDay.dayCount}</strong>{' '}
				<h6 className="text-black dark:text-white font-semibold text-xs sm:text-base">Streaks</h6>
			</span>
		</div>
	);
}
