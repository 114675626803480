import { useRef, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import Input from '../../components/input/input';
import Button from '../../components/button/button';
import { useResetPasswordMutation } from '../../services/api/auth';
import { isCustomErrorResponse } from '../../utils/errorUtils';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import PasswordPopup from '../../components/password-popup/PasswordPopup';
import { Helmet } from 'react-helmet';

export default function ResetPassword() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const token = searchParams.get('token');
	const [resetPassword, { isLoading }] = useResetPasswordMutation();
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [isRepeatPasswordVisible, setIsRepeatPasswordVisible] = useState(false);
	const [isPasswordPopupVisible, setIsPasswordPopupVisible] = useState(false);
	const [popupStyle, setPopupStyle] = useState({ top: 0, left: 0 });
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const resetPasswordInputRef = useRef<HTMLInputElement>(null);

	const handleResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (newPassword !== confirmPassword) {
			return toast.error('Passwords do not match');
		}

		try {
			const response = await resetPassword({ token, newPassword }).unwrap();

			if (response) {
				toast.success(response.message);
				navigate('/auth/login');
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				if (error.data.validationErrors) {
					error.data.validationErrors.forEach((validationError) => {
						const constraints = validationError.constraints;
						const messages = Object.values(constraints).join('. ');
						toast.error(messages);
					});
				} else {
					toast.error(error.data.message);
				}
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		}
	};

	const handleFocus = () => {
		setIsPasswordPopupVisible(true);
		if (resetPasswordInputRef.current) {
			const rect = resetPasswordInputRef.current.getBoundingClientRect();
			setPopupStyle({
				top: rect.top + window.scrollY,
				left: rect.right + window.scrollX
			});
		}
	};

	const handleBlur = () => {
		setIsPasswordPopupVisible(false);
	};

	return (
		<div className="flex flex-col justify-center items-center min-h-screen gap-4 w-full">
			<Helmet>
				<title>Reset Password</title>
				<meta name="description" content="Reset password page." />
			</Helmet>
			<Link to="/" className="text-[#8E4600] font-extrabold text-2xl base:text-5xl mb-10">
				Life Tracker
			</Link>
			<h2 className="font-bold text-5xl mb-10">Reset Password</h2>
			<form className="flex flex-col justify-center items-center gap-4 w-full" onSubmit={handleResetPassword}>
				<Input
					onFocus={handleFocus}
					onBlur={handleBlur}
					ref={resetPasswordInputRef}
					type={isPasswordVisible ? 'text' : 'password'}
					value={newPassword}
					onChange={(e) => setNewPassword(e.target.value)}
					placeholder="Password"
					name="password"
					required
					icon={
						isPasswordVisible ? (
							<IconEyeOff onClick={() => setIsPasswordVisible(false)} className="h-5 w-5 cursor-pointer" />
						) : (
							<IconEye onClick={() => setIsPasswordVisible(true)} className="h-5 w-5 cursor-pointer" />
						)
					}
				/>
				{isPasswordPopupVisible && (
					<div
						style={{
							position: 'absolute',
							...popupStyle
						}}
					>
						<PasswordPopup password={newPassword} />
					</div>
				)}
				<Input
					type={isRepeatPasswordVisible ? 'text' : 'password'}
					name="confirmPassword"
					value={confirmPassword}
					onChange={(e) => setConfirmPassword(e.target.value)}
					placeholder="Confirm Password"
					required
					icon={
						isRepeatPasswordVisible ? (
							<IconEyeOff onClick={() => setIsRepeatPasswordVisible(false)} className="h-5 w-5 cursor-pointer" />
						) : (
							<IconEye onClick={() => setIsRepeatPasswordVisible(true)} className="h-5 w-5 cursor-pointer" />
						)
					}
				/>
				<Button disabled={!newPassword || !confirmPassword || isLoading} variant="btn-primary-light" type="submit">
					{isLoading ? <span className="loading loading-spinner loading-md"></span> : 'Reset Password'}
				</Button>
			</form>
		</div>
	);
}
