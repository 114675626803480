import { Area } from 'react-easy-crop';

const createImage = (url: string): Promise<HTMLImageElement> =>
	new Promise((resolve, reject) => {
		const image = new Image();
		image.addEventListener('load', () => resolve(image));
		image.addEventListener('error', (error) => reject(error));
		image.setAttribute('crossOrigin', 'anonymous'); // This is needed to avoid CORS issues
		image.src = url;
	});

const getCroppedImg = async (imageSrc: string, pixelCrop: Area) => {
	const image = await createImage(imageSrc);
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');

	canvas.width = pixelCrop.width;
	canvas.height = pixelCrop.height;

	ctx?.drawImage(image, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, canvas.width, canvas.height);

	return new Promise<string>((resolve) => {
		canvas.toBlob((blob) => {
			if (blob) {
				const fileUrl = URL.createObjectURL(blob);
				resolve(fileUrl);
			}
		}, 'image/jpeg');
	});
};

export default getCroppedImg;
