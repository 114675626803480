import { IconCaretDown, IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import {
	GetFollowDataResponse,
	useLazyGetFollowDataQuery,
	useLazyGetFriendsFollowDataQuery,
	useSendFollowRequestMutation,
	useUnfollowMutation
} from '../../../services/api/profile';
import noProfileImg from '../../../assets/no-profile-picture.svg';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { isCustomErrorResponse } from '../../../utils/errorUtils';
import './Followings.css';
import { getMyFollowingsSuccess, unfollowSuccess } from '../../../states/profile/profileSlice';

import noFriends from '/src/assets/emptyStates/noFriends.webp';
import CustomModule from '../../customModule/customModule';
import { RootState } from '../../../states/store';
import { IFriend } from '../../../types/profile.types';
import Button from '../../button/button';

export default function Followings({
	setIsFollowing,
	isFollowing,
	setFollowRequestSendFlag,
	followRequestSendFlag
}: {
	setIsFollowing: React.Dispatch<React.SetStateAction<boolean>>;
	isFollowing?: boolean;
	setFollowRequestSendFlag: React.Dispatch<React.SetStateAction<boolean>>;
	followRequestSendFlag: boolean;
}) {
	const { userId } = useParams<string>();
	const dispatch = useDispatch();
	const [followDataRequest] = useLazyGetFollowDataQuery();
	const [friendsFollowDataRequest] = useLazyGetFriendsFollowDataQuery();
	const [isFollowDataLoading, setIsFollowDataLoading] = useState(false);
	const [isFriendsFollowDataLoading, setIsFriendsFollowDataLoading] = useState(false);
	const [followData, setFollowData] = useState<GetFollowDataResponse>();
	const [friendsFollowData, setFriendsFollowData] = useState<GetFollowDataResponse>();
	const [unfollowRequest, { isLoading: unfollowIsLoading }] = useUnfollowMutation();
	const [page, setPage] = useState(1);
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const [selectedFriendId, setSelectedFriendId] = useState('');
	const { profile } = useSelector((state: RootState) => state.profile);
	const [isFollowBackRequestLoading, setIsFollowBackRequestLoading] = useState(false);
	const [sendFollowRequest] = useSendFollowRequestMutation();
	const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

	useEffect(() => {
		const getFollowings = async () => {
			try {
				setIsFollowDataLoading(true);
				const response = await followDataRequest({
					page: page,
					limit: 10
				}).unwrap();
				if (response) {
					dispatch(getMyFollowingsSuccess(response.data.followings));
					setFollowData(response);
				}
			} catch (error) {
				if (isCustomErrorResponse(error)) {
					toast.error(error.data.message);
				} else if (error instanceof Error) {
					toast.error(error.message);
				} else {
					toast.error('An unknown error occurred. Please refresh the page.');
				}
			} finally {
				setIsFollowDataLoading(false);
			}
		};
		if (userId || !isAuthenticated) return;
		getFollowings();
	}, [page, profile.followings, isAuthenticated]);

	useEffect(() => {
		const getFriendsFollowings = async () => {
			try {
				setIsFriendsFollowDataLoading(true);
				const response = await friendsFollowDataRequest({
					userId: userId as string,
					page: page,
					limit: 10
				}).unwrap();
				if (response) {
					setFriendsFollowData(response);
				}
			} catch (error) {
				if (isCustomErrorResponse(error)) {
					toast.error(error.data.message);
				} else if (error instanceof Error) {
					toast.error(error.message);
				} else {
					toast.error('An unknown error occurred. Please refresh the page.');
				}
			} finally {
				setIsFriendsFollowDataLoading(false);
			}
		};
		if (!userId) return;
		getFriendsFollowings();
	}, [page, userId]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent | TouchEvent | PointerEvent | KeyboardEvent | FocusEvent | Event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setIsOpen(false);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const sendFollowBackRequest = async (userId: string) => {
		try {
			setIsFollowBackRequestLoading(true);
			const response = await sendFollowRequest(userId).unwrap();
			if (response) {
				toast.success(response.message);
				setFollowRequestSendFlag(true);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		} finally {
			setIsFollowBackRequestLoading(false);
		}
	};

	const unfollow = async () => {
		try {
			if (!selectedFriendId) return;
			const response = await unfollowRequest(selectedFriendId).unwrap();
			if (response) {
				dispatch(unfollowSuccess(response.followingId));
				toast.success(response.message);
				setFollowData(
					(prev) =>
						({
							...prev,
							data: {
								...prev?.data,
								followings: prev?.data.followings.filter((following) => following._id !== response.followingId)
							}
						}) as GetFollowDataResponse
				);
				setIsFollowing(false);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred');
			}
		} finally {
			(document.getElementById('friends-ays-modal') as HTMLDialogElement).close();
		}
	};

	const handleDropdownToggle = () => {
		setIsOpen((prev) => !prev);
	};

	const handlePageSelect = (page: number) => {
		setPage(page);
		setIsOpen(false);
	};

	const openAYSModule = (id: string) => {
		(document.getElementById('friends-ays-modal') as HTMLDialogElement).showModal();
		setSelectedFriendId(id);
	};

	const closeFollowingsModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		(document.getElementById('followings_modal') as HTMLDialogElement)?.close();
	};

	return (
		<div className="flex items-end">
			<CustomModule
				id="friends-ays-modal"
				title="Are you sure you want to unfollow?"
				description="You will no longer be able to see their actions."
				buttonText="Unfollow"
				onButtonClick={() => {
					unfollow();
				}}
				isLoading={unfollowIsLoading}
			/>
			<dialog id="followings_modal" className="modal">
				<div className="p-0 modal-box max-w-2xl min-h-[734px] bg-lt-light-popupBackground dark:bg-lt-dark-popupBackground border border-lt-light-popupBorder dark:border-lt-dark-popupBorder">
					<div className="sticky top-0 bg-lt-light-popupBackground dark:bg-lt-dark-popupBackground z-10 p-4">
						<form method="dialog">
							<button
								onClick={(e) => closeFollowingsModal(e)}
								className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
							>
								✕
							</button>
						</form>
						<h3 className="font-bold text-3xl">{!isFollowing && !userId ? 'My' : 'Friends'} Followings</h3>
					</div>
					<div>
						{!isFollowing && !userId ? (
							<>
								<div className="min-h-[600px] max-h-[800px] p-4 mt-2">
									{isFollowDataLoading ? (
										<div className="w-full min-h-[288px] h-full flex justify-center items-center">
											<span className="loading loading-spinner loading-xs"></span>
										</div>
									) : (
										<>
											{followData && followData.data.followings.length > 0 ? (
												<ul className="flex flex-col gap-3">
													{followData.data.followings.map((following: IFriend, _index: number) => (
														<li key={following._id} className="relative pb-4">
															<div className="flex justify-between items-center">
																<Link to={`/dashboard/profile/${following._id}`}>
																	<div className="flex items-center">
																		<img
																			src={following.profileImgUrl ?? noProfileImg}
																			alt="profile"
																			className="w-6 h-6 sm:w-10 sm:h-10 rounded-full object-cover"
																		/>
																		<span className="ml-2 font-semibold text-xl">{following.username}</span>
																	</div>
																</Link>
																<button
																	onClick={() => openAYSModule(following._id)}
																	type="button"
																	className="bg-lt-beige dark:bg-lt-dark-secondary-bg text-white dark:text-white px-2 py-1 text-xs sm:text-base rounded-md"
																>
																	Remove
																</button>
															</div>
															<div className="absolute bottom-0 left-0 right-0 h-px bg-lt-beige dark:bg-[#6f6b6b]"></div>
														</li>
													))}
												</ul>
											) : (
												<div className="min-h-[500px] w-full h-full flex flex-col gap-6 justify-center items-center">
													<img className="w-[200px] h-[135px]" src={noFriends} alt="No followings" />
													<span>No followings yet. Follow someone to see them here.</span>
												</div>
											)}
										</>
									)}
								</div>
								<div className="sticky bottom-0 flex-none z-20">
									<div className=" flex justify-between rounded-md fixed md:relative left-1/2 transform -translate-x-1/2 bg-lt-light-popupBackground dark:bg-lt-dark-secondary-bg w-full md:w-auto">
										<button
											disabled={isFollowDataLoading || page === 1 || followData?.pagination.totalFollowingPages === 0}
											onClick={() => {
												if (page === 1) return;
												setPage(page - 1);
											}}
											className="join-item btn bg-transparent  rounded-md border-none"
										>
											<IconChevronLeft />
										</button>
										<div ref={dropdownRef} className="relative">
											<button className="btn m-1 bg-transparent" onClick={handleDropdownToggle}>
												{followData?.pagination.page} of {followData?.pagination.totalFollowingPages}
												<IconCaretDown className="w-4 h-4" />
											</button>
											{isOpen && (
												<ul className="menu dropdown-content rounded-md bg-lt-light-popupBackground  dark:bg-lt-dark-popupBackground w-28 p-0 shadow absolute bottom-full left-0 z-20 mb-2">
													{Array(followData?.pagination.totalFollowingPages)
														.fill(null)
														.map((_, i) => (
															<li key={i}>
																<button
																	disabled={isFollowDataLoading}
																	className="h-10 rounded-sm border border-lt-beige dark:border-lt-dark-popupBorder w-full"
																	onClick={() => handlePageSelect(i + 1)}
																	value={i + 1}
																>
																	{i + 1}
																</button>
															</li>
														))}
												</ul>
											)}
										</div>
										<button
											disabled={
												isFollowDataLoading ||
												page === followData?.pagination.totalFollowingPages ||
												followData?.pagination.totalFollowingPages === 0
											}
											onClick={() => {
												if (page === 10) return;
												setPage(page + 1);
												followData?.pagination.totalFollowingPages;
											}}
											className="join-item btn  bg-transparent border-none"
										>
											<IconChevronRight />
										</button>
									</div>
								</div>
							</>
						) : (
							<>
								<div className="min-h-[600px] max-h-[800px] p-4 mt-2">
									{isFriendsFollowDataLoading ? (
										<div className="w-full min-h-[288px] h-full flex justify-center items-center">
											<span className="loading loading-spinner loading-xs"></span>
										</div>
									) : (
										<>
											{friendsFollowData && friendsFollowData.data.followings.length > 0 ? (
												<ul className="flex flex-col gap-3">
													{friendsFollowData.data.followings.map((following: IFriend, index: number) => (
														<li key={following._id} className="relative pb-4">
															<div className="flex justify-between items-center">
																<Link to={`/dashboard/profile/${following._id}`} className="">
																	<div className="flex items-center">
																		<img
																			src={following.profileImgUrl ?? noProfileImg}
																			alt="profile"
																			className="w-6 h-6 sm:w-10 sm:h-10 rounded-full object-cover"
																		/>
																		<span className="ml-2 font-semibold text-xl">{following.username}</span>
																	</div>
																</Link>
																{profile._id !== following._id && (
																	<>
																		{profile.followings.includes(following._id) ? (
																			<span className="text-gray-400">Following</span>
																		) : (
																			<Button
																				type="button"
																				variant="btn-secondary-light"
																				disabled={isFollowBackRequestLoading || followRequestSendFlag}
																				className="bg-lt-beige dark:bg-lt-dark-secondary-bg text-white dark:text-white px-2 py-1 text-xs sm:text-base rounded-md"
																				onClick={() => {
																					sendFollowBackRequest(following._id);
																				}}
																			>
																				Follow
																			</Button>
																		)}
																	</>
																)}
															</div>
															{index !== friendsFollowData.data.followings.length - 1 && (
																<div className="absolute bottom-0 left-0 right-0 h-px bg-lt-beige dark:bg-[#6f6b6b]"></div>
															)}
														</li>
													))}
												</ul>
											) : (
												<div className="min-h-[500px] w-full h-full flex flex-col gap-6 justify-center items-center">
													<img className="w-[200px] h-[135px]" src={noFriends} alt="No followings" />
													<span>No followings yet.</span>
												</div>
											)}
										</>
									)}
								</div>
								<div className="sticky bottom-0 flex-none z-20">
									<div className=" flex justify-between rounded-md fixed md:relative left-1/2 transform -translate-x-1/2 bg-lt-light-popupBackground dark:bg-lt-dark-secondary-bg w-full md:w-auto">
										<button
											disabled={
												isFriendsFollowDataLoading ||
												page === 1 ||
												friendsFollowData?.pagination.totalFollowingPages === 0
											}
											onClick={() => {
												if (page === 1) return;
												setPage(page - 1);
											}}
											className="join-item btn bg-transparent  rounded-md border-none"
										>
											<IconChevronLeft />
										</button>
										<div ref={dropdownRef} className="relative">
											<button className="btn m-1 bg-transparent" onClick={handleDropdownToggle}>
												{friendsFollowData?.pagination.page} of {friendsFollowData?.pagination.totalFollowingPages}
												<IconCaretDown className="w-4 h-4" />
											</button>
											{isOpen && (
												<ul className="menu dropdown-content rounded-md bg-lt-light-popupBackground  dark:bg-lt-dark-popupBackground w-28 p-0 shadow absolute bottom-full left-0 z-20 mb-2">
													{Array(friendsFollowData?.pagination.totalFollowingPages)
														.fill(null)
														.map((_, i) => (
															<li key={i}>
																<button
																	disabled={isFriendsFollowDataLoading}
																	className="h-10 rounded-sm border border-lt-beige dark:border-lt-dark-popupBorder w-full"
																	onClick={() => handlePageSelect(i + 1)}
																	value={i + 1}
																>
																	{i + 1}
																</button>
															</li>
														))}
												</ul>
											)}
										</div>
										<button
											disabled={
												isFriendsFollowDataLoading ||
												page === friendsFollowData?.pagination.totalFollowingPages ||
												friendsFollowData?.pagination.totalFollowingPages === 0
											}
											onClick={() => {
												if (page === 10) return;
												setPage(page + 1);
												friendsFollowData?.pagination.totalFollowingPages;
											}}
											className="join-item btn  bg-transparent border-none"
										>
											<IconChevronRight />
										</button>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</dialog>
			<div>
				<span className="flex gap-1">
					{userId && isFollowing ? (
						<>
							<strong>{friendsFollowData?.pagination.totalFollowings}</strong>
							<button
								disabled={friendsFollowData?.pagination.totalFollowings === 0}
								onClick={() => (document.getElementById('followings_modal') as HTMLDialogElement)?.showModal()}
								className="text-lt-beige font-semibold text-xs sm:text-base"
							>
								Followings
							</button>
						</>
					) : (
						<>
							<strong>{profile.followings.length}</strong>
							<button
								disabled={!isFollowing && followData?.data.followings.length === 0}
								onClick={() => (document.getElementById('followings_modal') as HTMLDialogElement)?.showModal()}
								className="text-lt-beige font-semibold text-xs sm:text-base"
							>
								Followings
							</button>
						</>
					)}
				</span>
			</div>
		</div>
	);
}
