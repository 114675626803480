import emailjs from 'emailjs-com';
import { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Button from '../../components/button/button';
import { Link } from 'react-router-dom';

export default function Whitelist() {
	const form = useRef<HTMLFormElement>(null);
	const [email, setEmail] = useState('');

	const whitelistRequest = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (form.current) {
			emailjs.sendForm('service_zgcz294', 'template_dd6h2lr', form.current, 'uL1bWw-DFCVEsHnAu').then(
				() => {
					toast.success('Request sent successfully!');
				},
				(err) => {
					console.error(err);
					toast.error('Failed to send request. Please try again later.');
				}
			);
		}
		if (form.current) {
			form.current.reset();
		}
	};
	return (
		<div className="w-full h-screen flex flex-col gap-20 items-center justify-center">
			<Link to="/" className="text-[#8E4600] font-extrabold text-2xl base:text-5xl mb-10">
				Life Tracker
			</Link>
			<h1 className="font-bold text-4xl">Whitelist Request</h1>
			<form ref={form} onSubmit={whitelistRequest} className="space-y-4">
				<div>
					<input
						onChange={(e) => {
							e.preventDefault();
						}}
						className="hidden"
						type="text"
						name="from_name"
						value={''}
					/>
					<input
						onChange={(e) => {
							e.preventDefault();
						}}
						className="hidden"
						type="text"
						name="user_email"
						value={''}
					/>
					<label htmlFor="message" className="block text-lg font-bold text-gray-700 dark:text-white">
						Email
					</label>
					<input
						onChange={(e) => setEmail(e.target.value)}
						value={email}
						placeholder="Enter your email"
						id="message"
						name="message"
						className="mt-1 p-2 px-2 min-w-[250px] w-full border border-gray-300 rounded-md dark:text-black max-h-80"
						required
					></input>
				</div>
				<Button variant="btn-primary-light" type="submit" className="w-full py-2 rounded-md">
					Send Request
				</Button>
				<h6 className="flex items-center justify-center">
					<Link to="/auth/register" className="underline">
						Go back to register.
					</Link>
				</h6>
			</form>
		</div>
	);
}
