import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './views/Home/Home';
import Register from './views/Auth/Register';
import axios from 'axios';
import { Toaster } from 'react-hot-toast';
import Login from './views/Auth/Login';
import EmailVerification from './views/Auth/EmailVerification';
import { VerifyEmail } from './views/Auth/VerifyEmail';
import ForgotPassword from './views/Auth/ForgotPassword';
import ResetPassword from './views/Auth/ResetPassword';
import TokenRefreshProvider from './provider/TokenRefreshProvider';
import { AuthLayout } from './views/Auth/Layout/AuthLayout';
import { DashboardLayout } from './views/Dashboard/Layout/DashboardLayout';
import HomeLayout from './views/Home/Layout/HomeLayout';
import MyTables from './views/Dashboard/MyTables/MyTables';

import Profile from './views/Dashboard/Profile/Profile';
import Shop from './views/Dashboard/Shop/Shop';
import Social from './views/Dashboard/Social/Social';
import Achievements from './views/Dashboard/Achievements';
import NotFound from './views/NotFound/NotFound';
import TableDetails from './views/Dashboard/MyTables/TableDetails/TableDetails';
import { ToastContainer } from 'react-toastify';
import MyJournal from './views/Dashboard/MyJournal/MyJournal';
import Whitelist from './views/Auth/Whitelist';
import TermsAndConditions from './views/Home/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from './views/Home/PrivacyPolicy/PrivacyPolicy';

function App() {
	axios.defaults.baseURL =
		process.env.NODE_ENV === 'development' ? import.meta.env.VITE_API_URL_TEST : import.meta.env.VITE_API_URL_PROD;
	axios.defaults.withCredentials = true;

	return (
		<BrowserRouter>
			<TokenRefreshProvider>
				<Toaster position="top-right" toastOptions={{ duration: 2500 }} />
				<ToastContainer />
				<Routes>
					{/* Home */}
					<Route element={<HomeLayout />}>
						<Route path="/" element={<Home />} />
						<Route path="/terms-and-conditions" element={<TermsAndConditions />} />
						<Route path="/privacy-policy" element={<PrivacyPolicy />} />
					</Route>

					{/* Auth */}
					<Route element={<AuthLayout />}>
						<Route path="/auth/register" element={<Register />} />
						<Route path="/auth/login" element={<Login />} />
						<Route path="/auth/email-verification" element={<EmailVerification />} />
						<Route path="/auth/verify-email" element={<VerifyEmail />} />
						<Route path="/auth/forgot-password" element={<ForgotPassword />}></Route>
						<Route path="/auth/reset-password" element={<ResetPassword />}></Route>
						<Route path="/auth/whitelist" element={<Whitelist />}></Route>
					</Route>

					{/* Dashboard */}
					<Route element={<DashboardLayout />}>
						<Route path="/dashboard/my-tables" element={<MyTables />}></Route>
						<Route path="/dashboard/my-tables/:tableId" element={<TableDetails />} />
						<Route path="/dashboard/my-journal" element={<MyJournal />}></Route>
						<Route path="/dashboard/profile" element={<Profile />}></Route>
						<Route path="/dashboard/profile/:userId" element={<Profile />}></Route>
						<Route path="/dashboard/shop" element={<Shop />}></Route>
						<Route path="/dashboard/social" element={<Social />}></Route>
						<Route path="/dashboard/achievements" element={<Achievements />}></Route>
					</Route>

					<Route path="*" element={<NotFound />} />
				</Routes>
			</TokenRefreshProvider>
		</BrowserRouter>
	);
}

export default App;
