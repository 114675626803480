import { useEffect, useRef, useState } from 'react';
import {
	GetFollowDataResponse,
	useLazyGetFollowDataQuery,
	useLazyGetFriendsFollowDataQuery,
	useSendFollowRequestMutation
} from '../../../services/api/profile';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../states/store';
import { isCustomErrorResponse } from '../../../utils/errorUtils';
import toast from 'react-hot-toast';
import { getMyFollowersSuccess } from '../../../states/profile/profileSlice';
import { IFriend } from '../../../types/profile.types';
import noFriends from '/src/assets/emptyStates/noFriends.webp';
import noProfileImg from '../../../assets/no-profile-picture.svg';
import { IconCaretDown, IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import Button from '../../button/button';

export default function Followers({
	isFollowing,
	followRequestSendFlag,
	setFollowRequestSendFlag
}: {
	isFollowing?: boolean;
	followRequestSendFlag: boolean;
	setFollowRequestSendFlag: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const { userId } = useParams<string>();
	const dispatch = useDispatch();
	const [isFollowDataLoading, setIsFollowDataLoading] = useState(false);
	const [followData, setFollowData] = useState<GetFollowDataResponse>();
	const [followDataRequest] = useLazyGetFollowDataQuery();
	const [friendsFollowDataRequest] = useLazyGetFriendsFollowDataQuery();
	const [isFriendsFollowDataLoading, setIsFriendsFollowDataLoading] = useState(false);
	const [friendsFollowData, setFriendsFollowData] = useState<GetFollowDataResponse>();
	const [page, setPage] = useState(1);
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const [sendFollowRequest] = useSendFollowRequestMutation();
	const [isFollowBackRequestLoading, setIsFollowBackRequestLoading] = useState(false);
	const { profile } = useSelector((state: RootState) => state.profile);
	const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

	useEffect(() => {
		const getFollowers = async () => {
			try {
				setIsFollowDataLoading(true);
				const response = await followDataRequest({
					page: page,
					limit: 10
				}).unwrap();
				if (response) {
					dispatch(getMyFollowersSuccess(response.data.followers));
					setFollowData(response);
				}
			} catch (error) {
				if (isCustomErrorResponse(error)) {
					toast.error(error.data.message);
				} else if (error instanceof Error) {
					toast.error(error.message);
				} else {
					toast.error('An unknown error occurred. Please refresh the page.');
				}
			} finally {
				setIsFollowDataLoading(false);
			}
		};
		if (userId || !isAuthenticated) return;
		getFollowers();
	}, [page, profile.followers, isAuthenticated]);

	useEffect(() => {
		const getFriendsFollowers = async () => {
			try {
				setIsFriendsFollowDataLoading(true);
				const response = await friendsFollowDataRequest({
					userId: userId as string,
					page: page,
					limit: 10
				}).unwrap();
				if (response) {
					setFriendsFollowData(response);
				}
			} catch (error) {
				if (isCustomErrorResponse(error)) {
					toast.error(error.data.message);
				} else if (error instanceof Error) {
					toast.error(error.message);
				} else {
					toast.error('An unknown error occurred. Please refresh the page.');
				}
			} finally {
				setIsFriendsFollowDataLoading(false);
			}
		};
		if (!userId) return;
		getFriendsFollowers();
	}, [page, userId]);

	const closeFollowersModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		(document.getElementById('followers_modal') as HTMLDialogElement)?.close();
	};

	const handleDropdownToggle = () => {
		setIsOpen((prev) => !prev);
	};

	const handlePageSelect = (page: number) => {
		setPage(page);
		setIsOpen(false);
	};

	const sendFollowBackRequest = async (userId: string) => {
		try {
			setIsFollowBackRequestLoading(true);
			const response = await sendFollowRequest(userId).unwrap();
			if (response) {
				toast.success(response.message);
				setFollowRequestSendFlag(true);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		} finally {
			setIsFollowBackRequestLoading(false);
		}
	};

	return (
		<div className="flex items-end">
			<dialog id="followers_modal" className="modal">
				<div className="p-0 modal-box max-w-2xl min-h-[734px] bg-lt-light-popupBackground dark:bg-lt-dark-popupBackground border border-lt-light-popupBorder dark:border-lt-dark-popupBorder">
					<div className="sticky top-0 bg-lt-light-popupBackground dark:bg-lt-dark-popupBackground z-10 p-4">
						<form method="dialog">
							<button
								onClick={(e) => closeFollowersModal(e)}
								className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
							>
								✕
							</button>
						</form>
						<h3 className="font-bold text-3xl">{!isFollowing && !userId ? 'My' : 'Friends'} Followers</h3>
					</div>
					<div>
						{!isFollowing && !userId ? (
							<>
								<div className="min-h-[600px] max-h-[800px] p-4 mt-2">
									{isFollowDataLoading || isFollowBackRequestLoading ? (
										<div className="w-full min-h-[288px] h-full flex justify-center items-center">
											<span className="loading loading-spinner loading-xs"></span>
										</div>
									) : (
										<>
											{followData && followData.data.followers.length > 0 ? (
												<ul className="flex flex-col gap-3">
													{followData.data.followers.map((follower: IFriend, _index: number) => (
														<li key={follower._id} className="relative pb-4">
															<div className="flex justify-between items-center">
																<Link to={`/dashboard/profile/${follower._id}`} className="">
																	<div className="flex items-center">
																		<img
																			src={follower.profileImgUrl ?? noProfileImg}
																			alt="profile"
																			className="w-6 h-6 sm:w-10 sm:h-10 rounded-full object-cover"
																		/>
																		<span className="ml-2 font-semibold text-xl">{follower.username}</span>
																	</div>
																</Link>
																{profile.followings.includes(follower._id) ? (
																	<span className="text-gray-400">Following</span>
																) : (
																	<Button
																		type="button"
																		variant="btn-secondary-light"
																		disabled={isFollowBackRequestLoading || followRequestSendFlag}
																		className="bg-lt-beige dark:bg-lt-dark-secondary-bg text-white dark:text-white px-2 py-1 text-xs sm:text-base rounded-md"
																		onClick={() => {
																			sendFollowBackRequest(follower._id);
																		}}
																	>
																		Follow Back
																	</Button>
																)}
															</div>
															<div className="absolute bottom-0 left-0 right-0 h-px bg-lt-beige dark:bg-[#6f6b6b]"></div>
														</li>
													))}
												</ul>
											) : (
												<div className="min-h-[500px] w-full h-full flex flex-col gap-6 justify-center items-center">
													<img className="w-[200px] h-[135px]" src={noFriends} alt="No followers" />
													<span>No followers yet.</span>
												</div>
											)}
										</>
									)}
								</div>
								<div className="sticky bottom-0 flex-none z-20">
									<div className=" flex justify-between rounded-md fixed md:relative left-1/2 transform -translate-x-1/2 bg-lt-light-popupBackground dark:bg-lt-dark-secondary-bg w-full md:w-auto">
										<button
											disabled={isFollowDataLoading || page === 1 || followData?.pagination.totalFollowingPages === 0}
											onClick={() => {
												if (page === 1) return;
												setPage(page - 1);
											}}
											className="join-item btn bg-transparent  rounded-md border-none"
										>
											<IconChevronLeft />
										</button>
										<div ref={dropdownRef} className="relative">
											<button className="btn m-1 bg-transparent" onClick={handleDropdownToggle}>
												{followData?.pagination.page} of {followData?.pagination.totalFollowingPages}
												<IconCaretDown className="w-4 h-4" />
											</button>
											{isOpen && (
												<ul className="menu dropdown-content rounded-md bg-lt-light-popupBackground  dark:bg-lt-dark-popupBackground w-28 p-0 shadow absolute bottom-full left-0 z-20 mb-2">
													{Array(followData?.pagination.totalFollowingPages)
														.fill(null)
														.map((_, i) => (
															<li key={i}>
																<button
																	disabled={isFollowDataLoading}
																	className="h-10 rounded-sm border border-lt-beige dark:border-lt-dark-popupBorder w-full"
																	onClick={() => handlePageSelect(i + 1)}
																	value={i + 1}
																>
																	{i + 1}
																</button>
															</li>
														))}
												</ul>
											)}
										</div>
										<button
											disabled={
												isFollowDataLoading ||
												page === followData?.pagination.totalFollowingPages ||
												followData?.pagination.totalFollowingPages === 0
											}
											onClick={() => {
												if (page === 10) return;
												setPage(page + 1);
												followData?.pagination.totalFollowingPages;
											}}
											className="join-item btn  bg-transparent border-none"
										>
											<IconChevronRight />
										</button>
									</div>
								</div>
							</>
						) : (
							<>
								<div className="min-h-[600px] max-h-[800px] p-4 mt-2">
									{isFriendsFollowDataLoading ? (
										<div className="w-full min-h-[288px] h-full flex justify-center items-center">
											<span className="loading loading-spinner loading-xs"></span>
										</div>
									) : (
										<>
											{friendsFollowData && friendsFollowData.data.followers.length > 0 ? (
												<ul className="flex flex-col gap-3">
													{friendsFollowData.data.followers.map((follower: IFriend, index: number) => (
														<li key={follower._id} className="relative pb-4">
															<div className="flex justify-between items-center">
																<Link to={`/dashboard/profile/${follower._id}`} className="">
																	<div className="flex items-center">
																		<img
																			src={follower.profileImgUrl ?? noProfileImg}
																			alt="profile"
																			className="w-6 h-6 sm:w-10 sm:h-10 rounded-full object-cover"
																		/>
																		<span className="ml-2 font-semibold text-xl">{follower.username}</span>
																	</div>
																</Link>
																{profile._id !== follower._id && (
																	<>
																		{profile.followings.includes(follower._id) ? (
																			<span className="text-gray-400">Following</span>
																		) : (
																			<Button
																				type="button"
																				variant="btn-secondary-light"
																				disabled={isFollowBackRequestLoading || followRequestSendFlag}
																				className="bg-lt-beige dark:bg-lt-dark-secondary-bg text-white dark:text-white px-2 py-1 text-xs sm:text-base rounded-md"
																				onClick={() => {
																					sendFollowBackRequest(follower._id);
																				}}
																			>
																				Follow
																			</Button>
																		)}
																	</>
																)}
															</div>
															{index !== friendsFollowData.data.followings.length - 1 && (
																<div className="absolute bottom-0 left-0 right-0 h-px bg-lt-beige dark:bg-[#6f6b6b]"></div>
															)}
														</li>
													))}
												</ul>
											) : (
												<div className="min-h-[500px] w-full h-full flex flex-col gap-6 justify-center items-center">
													<img className="w-[200px] h-[135px]" src={noFriends} alt="No followers" />
													<span>No followers yet.</span>
												</div>
											)}
										</>
									)}
								</div>
								<div className="sticky bottom-0 flex-none z-20">
									<div className=" flex justify-between rounded-md fixed md:relative left-1/2 transform -translate-x-1/2 bg-lt-light-popupBackground dark:bg-lt-dark-secondary-bg w-full md:w-auto">
										<button
											disabled={
												isFriendsFollowDataLoading ||
												page === 1 ||
												friendsFollowData?.pagination.totalFollowingPages === 0
											}
											onClick={() => {
												if (page === 1) return;
												setPage(page - 1);
											}}
											className="join-item btn bg-transparent  rounded-md border-none"
										>
											<IconChevronLeft />
										</button>
										<div ref={dropdownRef} className="relative">
											<button className="btn m-1 bg-transparent" onClick={handleDropdownToggle}>
												{friendsFollowData?.pagination.page} of {friendsFollowData?.pagination.totalFollowingPages}
												<IconCaretDown className="w-4 h-4" />
											</button>
											{isOpen && (
												<ul className="menu dropdown-content rounded-md bg-lt-light-popupBackground  dark:bg-lt-dark-popupBackground w-28 p-0 shadow absolute bottom-full left-0 z-20 mb-2">
													{Array(friendsFollowData?.pagination.totalFollowingPages)
														.fill(null)
														.map((_, i) => (
															<li key={i}>
																<button
																	disabled={isFriendsFollowDataLoading}
																	className="h-10 rounded-sm border border-lt-beige dark:border-lt-dark-popupBorder w-full"
																	onClick={() => handlePageSelect(i + 1)}
																	value={i + 1}
																>
																	{i + 1}
																</button>
															</li>
														))}
												</ul>
											)}
										</div>
										<button
											disabled={
												isFriendsFollowDataLoading ||
												page === friendsFollowData?.pagination.totalFollowingPages ||
												friendsFollowData?.pagination.totalFollowingPages === 0
											}
											onClick={() => {
												if (page === 10) return;
												setPage(page + 1);
												friendsFollowData?.pagination.totalFollowingPages;
											}}
											className="join-item btn  bg-transparent border-none"
										>
											<IconChevronRight />
										</button>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</dialog>
			<span className="flex gap-1">
				{userId && isFollowing ? (
					<>
						<strong>{friendsFollowData?.pagination.totalFollowers}</strong>
						<button
							disabled={friendsFollowData?.pagination.totalFollowers === 0}
							onClick={() => (document.getElementById('followers_modal') as HTMLDialogElement)?.showModal()}
							className="text-lt-beige font-semibold text-xs sm:text-base"
						>
							Followers
						</button>
					</>
				) : (
					<>
						<strong>{followData?.pagination.totalFollowers}</strong>{' '}
						<button
							disabled={followData?.pagination.totalFollowers === 0}
							onClick={() => (document.getElementById('followers_modal') as HTMLDialogElement)?.showModal()}
							className="text-lt-beige font-semibold text-xs sm:text-base"
						>
							Followers
						</button>
					</>
				)}
			</span>
		</div>
	);
}
