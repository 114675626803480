import { createApi } from '@reduxjs/toolkit/query/react';
import { ISticker } from '../../types/sticker.types';
import { IAchievement, IAchievementClaim } from '../../types/achievement.types';
import { ITable } from '../../types/myTables.types';
import { IFollowData, IStreakDay } from '../../types/profile.types';
import { JournalPage } from '../../views/Dashboard/MyJournal/ItemTypes';
import baseQueryWithReauth from './customBaseQuery';

export interface ImageUploadResponse {
	imageUrl: string;
	message: string;
}

export interface GetMyAchievementsResponse {
	data: IAchievement[];
}
export interface IProfile {
	_id: string;
	username: string;
	email: string;
	profileImgUrl: string;
	profileBackgroundUrl: string;
	stickers: string[];
	achievements: IAchievementClaim[];
	subscription: {
		_id: string;
		subscriptionType: SubscriptionType;
		subscriptionEndDate: Date;
		subscriptionStartDate: Date;
	};
	tables: string[];
	myJournal: JournalPage[];
	followings: string[];
	followers: string[];
	streakDay: IStreakDay;
	appMoney: number;
	followRequestSent?: boolean;
}

export interface GetFollowDataResponse {
	data: IFollowData;
	pagination: {
		page: number;
		totalFollowerPages: number;
		totalFollowingPages: number;
		totalFollowers: number;
		totalFollowings: number;
	};
}

export enum SubscriptionType {
	LIFETIME = 'Lifetime',
	MONTHLY = 'Monthly',
	YEARLY = 'Yearly',
	FREE = 'Free'
}

export interface StreakDayResponse {
	streakDay: IStreakDay;
	message: string;
}

export interface IFollowRequest {
	_id: string;
	sender: { _id: string; username: string; profileImgUrl: string };
	receiver: { _id: string; username: string; profileImgUrl: string };
	status: FollowRequestStatus;
}

export interface IFollowRequestResponse {
	_id: string;
	sender: string;
	receiver: string;
	status: FollowRequestStatus;
}

export interface SendFollowRequestResponse {
	message: string;
	achievements: IAchievement[];
	data: IFollowRequest;
}
export interface GetFollowRequestsResponse {
	data: IFollowRequest[];
	pagination: {
		totalFollowRequests: number;
		page: number;
		totalPages: number;
	};
}

export enum FollowRequestStatus {
	PENDING = 'pending',
	ACCEPTED = 'accepted',
	DECLINED = 'declined'
}

export interface StickersResponse {
	data: ISticker[];
	message: string;
}

export interface UnfollowResponse {
	message: string;
	followingId: string;
}

export interface FriendsJournalResponse {
	data: IFriendsJournal;
}

export interface IFriendsJournal {
	tables: ITable[];
	journal: JournalPage[];
}

export const profileApi = createApi({
	reducerPath: 'profileApi',
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		uploadProfilePhoto: builder.mutation<ImageUploadResponse, FormData>({
			query: (formData) => ({
				url: 'api/profile/upload-profile-photo',
				method: 'POST',
				body: formData
			})
		}),
		uploadBackgroundPhoto: builder.mutation<ImageUploadResponse, FormData>({
			query: (formData) => ({
				url: 'api/profile/upload-profile-background',
				method: 'POST',
				body: formData
			})
		}),
		viewProfile: builder.query<IProfile, string>({
			query: (userId) => `api/profile/view-profile/${userId}`
		}),
		unfollow: builder.mutation<UnfollowResponse, string>({
			query: (userId) => ({
				url: `api/profile/unfollow/${userId}`,
				method: 'POST'
			})
		}),
		getFollowData: builder.query<GetFollowDataResponse, { page?: number; limit?: number }>({
			query: ({ page, limit }) => ({
				url: `api/profile/get-my-follow-data?page=${page}&limit=${limit}`
			})
		}),
		getAchievements: builder.query<GetMyAchievementsResponse, void>({
			query: () => 'api/profile/get-my-achievements'
		}),
		getFriendsAchievements: builder.query<GetMyAchievementsResponse, string>({
			query: (userId) => `api/friend-details/get-friends-achievements/${userId}`
		}),
		updateStreakDay: builder.query<StreakDayResponse, void>({
			query: () => 'api/profile/update-streak-day'
		}),
		getFriendsStreakDay: builder.query<StreakDayResponse, string>({
			query: (userId) => `api/friend-details/get-friends-streak-day/${userId}`
		}),
		sendFollowRequest: builder.mutation<SendFollowRequestResponse, string>({
			query: (receiverId) => ({
				url: `api/profile/send-follow-request/${receiverId}`,
				method: 'POST'
			})
		}),
		respondToFollowRequest: builder.mutation<
			SendFollowRequestResponse,
			{
				requestId: string;
				status: FollowRequestStatus;
			}
		>({
			query: (body) => ({
				url: 'api/profile/respond-follow-request',
				method: 'POST',
				body: body
			})
		}),
		getFollowRequests: builder.query<GetFollowRequestsResponse, { page?: number; limit?: number }>({
			query: ({ page, limit }) => `api/profile/get-follow-requests?page=${page}&limit=${limit}`
		}),
		getMyStickers: builder.query<StickersResponse, void>({
			query: () => 'api/profile/get-my-stickers'
		}),
		getFriendsStickers: builder.query<StickersResponse, string>({
			query: (userId) => `api/friend-details/get-friends-stickers/${userId}`
		}),
		getFriendsJournal: builder.query<FriendsJournalResponse, string>({
			query: (userId) => `api/friend-details/get-friends-journal/${userId}`
		}),
		getFriendsFollowData: builder.query<GetFollowDataResponse, { userId: string; page?: number; limit?: number }>({
			query: ({ userId, page, limit }) =>
				`api/friend-details/get-friends-follow-data/${userId}?page=${page}&limit=${limit}`
		}),
		deleteProfile: builder.mutation<{ message: string }, void>({
			query: () => ({
				url: 'api/profile/delete-profile',
				method: 'DELETE'
			})
		}),
		changePassword: builder.mutation<{ message: string }, { currentPassword: string; newPassword: string }>({
			query: (body) => ({
				url: 'api/profile/change-password',
				method: 'POST',
				body: body
			})
		})
	})
});

export const {
	useUploadProfilePhotoMutation,
	useUploadBackgroundPhotoMutation,
	useLazyViewProfileQuery,
	useUnfollowMutation,
	useLazyGetAchievementsQuery,
	useLazyUpdateStreakDayQuery,
	useSendFollowRequestMutation,
	useRespondToFollowRequestMutation,
	useLazyGetFollowRequestsQuery,
	useLazyGetFriendsAchievementsQuery,
	useLazyGetFriendsStreakDayQuery,
	useLazyGetMyStickersQuery,
	useLazyGetFriendsStickersQuery,
	useLazyGetFriendsJournalQuery,
	useDeleteProfileMutation,
	useLazyGetFollowDataQuery,
	useLazyGetFriendsFollowDataQuery,
	useChangePasswordMutation
} = profileApi;
