import { IconLicense, IconShoppingBag, IconTableHeart, IconUserCircle, IconUsersGroup } from '@tabler/icons-react';
import { Link, useLocation } from 'react-router-dom';

const bottomMenuItems = [
	{ name: 'My Tables', icon: <IconTableHeart />, path: '/dashboard/my-tables' },
	{ name: 'Profile', icon: <IconUserCircle />, path: '/dashboard/profile' },
	{ name: 'Social', icon: <IconUsersGroup />, path: '/dashboard/social' },
	// { name: 'My Journal', icon: <IconDeviceGamepad2 />, path: '/dashboard/my-journal' },
	{ name: 'Achievements', icon: <IconLicense />, path: '/dashboard/achievements' },
	{ name: 'Shop', icon: <IconShoppingBag />, path: '/dashboard/shop' }
];

export default function BottomMenu() {
	const location = useLocation();

	return (
		<div className="fixed bottom-0 left-0 right-0 w-full bg-lt-cream dark:bg-lt-dark-secondary-bg border-t border-lt-beige dark:border-lt-dark-secondary-border flex justify-around items-center z-20 p-2">
			{bottomMenuItems.map((item, index) => (
				<Link
					key={index}
					to={item.path}
					className={`flex flex-col items-center justify-center text-base min-h-10 ${location.pathname === item.path ? 'text-yellow-500' : ''}`}
				>
					{item.icon}
					{/* <span>{item.name}</span> */}
				</Link>
			))}
		</div>
	);
}
