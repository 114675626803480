import { Helmet } from 'react-helmet';

export default function TermsAndConditions() {
	return (
		<div>
			<Helmet>
				<title>Life Tracker - Terms and Conditions</title>
				<meta name="description" content="Life Tracker's terms and conditions." />
			</Helmet>
			TermsAndConditions
		</div>
	);
}
