import React from 'react';
import Lottie from 'lottie-react';
import fireAnimation from '../../../assets/animations/fireAnimation.json';

interface StreakDisplayProps {
	streakCount: number;
}

const StreakDisplay: React.FC<StreakDisplayProps> = () => {
	return (
		<div>
			<Lottie animationData={fireAnimation} className={`lottie-container w-12 h-12`} loop />
		</div>
	);
};

export default StreakDisplay;
