import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import Input from '../../components/input/input';
import Button from '../../components/button/button';
import { useForgotPasswordMutation } from '../../services/api/auth';
import { isCustomErrorResponse } from '../../utils/errorUtils';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function ForgotPassword() {
	const [email, setEmail] = useState('');
	const [countdown, setCountdown] = useState<number>(60);
	const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

	const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

	const handleForgotPassword = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsButtonDisabled(true);
		setCountdown(60);
		try {
			const response = await forgotPassword({ email }).unwrap();

			if (response) {
				toast.success(response.message);
				setEmail('');
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again.');
			}
		}
	};

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (isButtonDisabled && countdown > 0) {
			timer = setTimeout(() => setCountdown((prev) => prev - 1), 1000);
		} else if (countdown === 0) {
			setIsButtonDisabled(false);
		}
		return () => clearTimeout(timer);
	}, [isButtonDisabled, countdown]);

	return (
		<div className="flex flex-col justify-center items-center min-h-screen gap-4 w-full">
			<Helmet>
				<title>Forgot Password</title>
				<meta name="description" content="Forgot password page." />
			</Helmet>
			<Link to="/" className="text-[#8E4600] font-extrabold text-2xl base:text-5xl mb-10">
				Life Tracker
			</Link>
			<h2 className="font-bold text-5xl mb-10">Forgot Password</h2>
			<form className="flex flex-col justify-center items-center gap-4 w-full" onSubmit={handleForgotPassword}>
				<Input
					type="email"
					name="email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					placeholder="Enter your email"
					required
				/>
				<Button disabled={!email || isLoading || isButtonDisabled} variant="btn-primary-light" type="submit">
					{isLoading ? (
						<span className="loading loading-spinner loading-md"></span>
					) : isButtonDisabled ? (
						`Resend in ${countdown}s`
					) : (
						'Send Reset Email'
					)}
				</Button>
			</form>
			<Link className="underline" to="/auth/login">
				Go back to login
			</Link>
		</div>
	);
}
