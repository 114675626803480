import { IconAward, IconTrophy } from '@tabler/icons-react';
import { useLazyGetAchievementsQuery, useLazyGetFriendsAchievementsQuery } from '../../../services/api/profile';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { IAchievement } from '../../../types/achievement.types';
import { useDispatch, useSelector } from 'react-redux';
import { getMyAchievementsSuccess } from '../../../states/achievements/achievementsSlice';
import { isCustomErrorResponse } from '../../../utils/errorUtils';
import toast from 'react-hot-toast';
import { RootState } from '../../../states/store';

export default function Achievements({ isFollowing }: { isFollowing?: boolean }) {
	const dispatch = useDispatch();
	const { userId } = useParams<string>();
	const [getMyAchievementsRequest] = useLazyGetAchievementsQuery();
	const [getFriendsAchievementsRequest] = useLazyGetFriendsAchievementsQuery();
	const [friendsAchievements, setFriendsAchievements] = useState<IAchievement[]>();
	const myAchievements = useSelector((state: RootState) => state.achievements.myAchievements);
	const profile = useSelector((state: RootState) => state.profile.profile);

	useEffect(() => {
		const getFriendsAchievements = async () => {
			if (!userId) return;

			try {
				const response = await getFriendsAchievementsRequest(userId).unwrap();
				if (response) setFriendsAchievements(response.data);
			} catch (error) {
				if (isCustomErrorResponse(error)) {
					toast.error(error.data.message);
				} else if (error instanceof Error) {
					toast.error(error.message);
				} else {
					toast.error('An unknown error occurred. Please refresh the page.');
				}
			}
		};

		if (isFollowing) {
			getFriendsAchievements();
		}
	}, [isFollowing]);

	useEffect(() => {
		const getMyAchievements = async () => {
			if (myAchievements && myAchievements.length > 0) return;
			try {
				const response = await getMyAchievementsRequest().unwrap();

				if (response) {
					dispatch(getMyAchievementsSuccess(response.data));
				}
			} catch (error) {
				if (isCustomErrorResponse(error)) {
					toast.error(error.data.message);
				} else if (error instanceof Error) {
					toast.error(error.message);
				} else {
					toast.error('An unknown error occurred. Please refresh the page.');
				}
			}
		};

		if (!isFollowing) {
			getMyAchievements();
		}
	}, [isFollowing]);

	const closeAchievementModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		(document.getElementById('achievements_modal') as HTMLDialogElement)?.close();
	};

	return (
		<div className="flex gap-1 items-end">
			<dialog id="achievements_modal" className="modal">
				<div className="p-0 modal-box max-w-4xl md:min-h-[800px] bg-lt-light-popupBackground dark:bg-lt-dark-popupBackground border border-lt-light-popupBorder dark:border-lt-dark-popupBorder">
					<div className="sticky top-0 bg-lt-light-popupBackground dark:bg-lt-dark-popupBackground p-4">
						<form method="dialog">
							<button
								onClick={(e) => closeAchievementModal(e)}
								className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
							>
								✕
							</button>
						</form>
						<h3 className="font-bold text-3xl">{!isFollowing && !userId ? 'My' : 'Friends'} Achievements</h3>
					</div>

					<div className="grid grid-cols-3 gap-3 p-4">
						{!isFollowing && !userId ? (
							<>
								{myAchievements?.map((achievement) => (
									<Link
										to="/dashboard/achievements"
										key={achievement._id}
										className="hover:bg-lt-light-secondary-hover-bg min-h-[140px]  md:min-h-[200px] dark:hover:bg-lt-dark-secondary-hover-bg border border-lt-light-popupBorder dark:border-[#6f6b6b] hover:bg-opacity-50 p-2 rounded-default"
									>
										<div className="flex flex-col h-full items-center justify-around">
											<IconAward className=" w-6 h-6 sm:w-8 sm:h-8 md:w-6 md:h-6 lg:w-8 lg:h-8 text-[#eeda77]" />
											<div className="flex items-center">
												<div className="flex flex-col">
													<span className="ml-2 font-bold text-xs  sm:text-base lg:text-lg text-center">
														{achievement.title}
													</span>
													<span className="ml-2 text-2xs sm:text-xs md:text-sm lg:text-base text-center mt-2">
														{achievement.description}
													</span>
												</div>
											</div>
											<span className="font-bold text-2xs  sm:text-sm lg:text-base">
												{profile.achievements.find((a) => a._id === achievement._id)?.isClaimed ? (
													<h6 className="text-[#8686866b]">Claimed</h6>
												) : (
													<h6 className="text-lt-beige">Not Claimed</h6>
												)}
											</span>
										</div>
									</Link>
								))}
							</>
						) : (
							<>
								{friendsAchievements?.map((achievement) => (
									<Link
										to="/dashboard/achievements"
										key={achievement._id}
										className="hover:bg-lt-light-secondary-hover-bg  min-h-[200px] dark:hover:bg-lt-dark-secondary-hover-bg border border-lt-light-popupBorder dark:border-[#6f6b6b] hover:bg-opacity-50 p-2 rounded-default"
									>
										<div className="flex flex-col h-full items-center justify-around">
											<IconAward className=" w-6 h-6 sm:w-8 sm:h-8 md:w-6 md:h-6 lg:w-8 lg:h-8 text-[#eeda77]" />
											<div className="flex items-center">
												<div className="flex flex-col">
													<span className="ml-2 font-bold text-xs  sm:text-base lg:text-lg text-center">
														{achievement.title}
													</span>
													<span className="ml-2 text-2xs sm:text-xs md:text-sm lg:text-base text-center mt-2">
														{achievement.description}
													</span>
												</div>
											</div>
										</div>
									</Link>
								))}
							</>
						)}
					</div>
				</div>
			</dialog>
			<IconTrophy />
			<span className="flex gap-1">
				<strong>{isFollowing ? friendsAchievements?.length : myAchievements?.length}</strong>{' '}
				<button
					disabled={(isFollowing && !friendsAchievements?.length) || (!isFollowing && !myAchievements?.length)}
					onClick={() => (document.getElementById('achievements_modal') as HTMLDialogElement)?.showModal()}
					className="text-lt-beige font-semibold"
				>
					Achievements
				</button>
			</span>
		</div>
	);
}
