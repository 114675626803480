import React from 'react';
import { IconAward } from '@tabler/icons-react';
import Confetti from 'react-confetti';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Link } from 'react-router-dom';
import './Achievement.css';

interface AchievementToastProps {
	title: string;
	threshold: number;
	description: string;
}

const AchievementToast: React.FC<AchievementToastProps> = ({ title, description }) => {
	return (
		<div className="flex items-center gap-2 ">
			<Confetti />

			<IconAward className="w-8 h-8" />

			<div className="flex items-center justify-center">
				<div className="flex flex-col">
					<h1 className="font-bold">{title ?? 'title'}</h1>
					<h2>{description ?? 'desc'}</h2>
					<Link className="text-lt-beige underline" to="/dashboard/achievements">
						Go to achievements for collect your coins
					</Link>
				</div>
				<div className="wrapper">
					<CircularProgressbar
						value={100}
						text="100%"
						styles={buildStyles({
							textSize: '24px',
							textColor: '#D4A373',
							pathColor: '#D4A373',
							trailColor: '#FEFAE0'
						})}
					/>
				</div>
			</div>
		</div>
	);
};

export default AchievementToast;
