import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { persistor, store } from './states/store';
import { PersistGate } from 'redux-persist/integration/react';
import 'react-tooltip/dist/react-tooltip.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';
import deskImage from '/src/assets/landing-page/desk.webp';
import { useGLTF } from '@react-three/drei';
import { useState, useEffect } from 'react';
import LoadingScreen from './components/loadingScreen/LoadingScreen';

const Main = () => {
	document.getElementById('loading-screen')?.classList.add('hidden');
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const img = new Image();
		img.src = deskImage;
		img.onload = () => {
			// Load desk image
			setIsLoading(false);
		};
		img.onerror = () => {
			console.error('Image failed to load');
		};

		useGLTF.preload('../src/views/Home/components/bookAnimation.glb');
	}, []);

	return isLoading ? (
		<LoadingScreen />
	) : (
		<GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<App />
				</PersistGate>
			</Provider>
		</GoogleOAuthProvider>
	);
};

ReactDOM.createRoot(document.getElementById('root')!).render(<Main />);
