import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import Input from '../../components/input/input';
import Button from '../../components/button/button';
import {
	useGoogleSignupMutation,
	useRegisterMutation,
	useResendVerificationEmailMutation
} from '../../services/api/auth';
import { RegisterRequest } from '../../types/auth.types';
import { CustomErrorResponse } from '../../types/error.types';
import PasswordPopup from '../../components/password-popup/PasswordPopup';
import { useDispatch } from 'react-redux';
import { setEmail } from '../../states/auth/emailSlice';
import { GoogleLogin } from '@react-oauth/google';
import './Auth.css';
import { Helmet } from 'react-helmet';

export default function Register() {
	const dispatch = useDispatch();

	const [registerData, setRegisterData] = useState<RegisterRequest>({
		username: '',
		email: '',
		password: '',
		password2: ''
	});
	const [register, { isLoading }] = useRegisterMutation();
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [isRepeatPasswordVisible, setIsRepeatPasswordVisible] = useState(false);
	const [isPasswordPopupVisible, setIsPasswordPopupVisible] = useState(false);
	const [popupStyle, setPopupStyle] = useState({ top: 0, left: 0 });
	const [googleSignupRequest] = useGoogleSignupMutation();
	const [resendVerificationEmail] = useResendVerificationEmailMutation();

	const registerInputRef = useRef<HTMLInputElement>(null);

	const navigate = useNavigate();

	const handleRegister = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		try {
			const response = await register(registerData).unwrap();

			if (response) {
				resetRegisterData();
				toast.success(response.message);
				dispatch(setEmail(registerData.email));
				navigate('/auth/email-verification');
				await resendVerificationEmail({ email: registerData.email }).unwrap();
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				if (error.data.validationErrors) {
					error.data.validationErrors.forEach((validationError) => {
						const constraints = validationError.constraints;
						const messages = Object.values(constraints).join('. ');
						toast.error(messages);
					});
				} else {
					toast.error(error.data.message);
				}
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		}
	};

	const resetRegisterData = () => {
		setRegisterData({
			username: '',
			email: '',
			password: '',
			password2: ''
		});
	};

	const handleFocus = () => {
		setIsPasswordPopupVisible(true);
		if (registerInputRef.current) {
			const rect = registerInputRef.current.getBoundingClientRect();
			setPopupStyle({
				top: rect.top + window.scrollY,
				left: rect.right + window.scrollX
			});
		}
	};

	const handleBlur = () => {
		setIsPasswordPopupVisible(false);
	};

	useEffect(() => {
		const handleResize = () => {
			if (registerInputRef.current) {
				const rect = registerInputRef.current.getBoundingClientRect();
				setPopupStyle({
					top: rect.top + window.scrollY,
					left: rect.right + window.scrollX
				});
			}
		};
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	function isCustomErrorResponse(error: unknown): error is CustomErrorResponse {
		return (
			typeof error === 'object' &&
			error !== null &&
			'data' in error &&
			typeof (error as CustomErrorResponse).data.message === 'string'
		);
	}

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setRegisterData((prevState) => ({
			...prevState,
			[name]: value
		}));
	};

	const handleSignupSuccess = async (response: any) => {
		try {
			const res = await googleSignupRequest({
				token: response.credential
			}).unwrap();
			if (res) {
				toast.success(res.message);
				dispatch(setEmail(res.email));
				navigate('/auth/login');
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		}
	};

	return (
		<div className="flex min-h-screen flex-col justify-center items-center">
			<Helmet>
				<title>Life Tracker - Signup</title>
				<meta name="description" content="Signup to Life Tracker" />
			</Helmet>
			<div className="flex flex-col justify-center items-center  gap-10 w-full bg-white dark:bg-lt-dark-popupBackground max-w-[700px] min-h-[800px] border border-lt-beige rounded-lg shadow-xl">
				<Link to="/" className="text-[#8E4600] font-extrabold text-2xl base:text-5xl mb-10 ">
					Life Tracker
				</Link>
				<h2 className="font-bold text-2xl">Signup</h2>
				<form onSubmit={handleRegister} className=" flex flex-col justify-center items-center gap-4 w-full">
					<Input
						type="text"
						placeholder="Username"
						name="username"
						required
						value={registerData.username}
						onChange={handleChange}
					/>
					<Input
						type="text"
						placeholder="Email"
						name="email"
						required
						value={registerData.email}
						onChange={handleChange}
					/>
					<Input
						onFocus={handleFocus}
						onBlur={handleBlur}
						ref={registerInputRef}
						type={isPasswordVisible ? 'text' : 'password'}
						value={registerData.password}
						onChange={handleChange}
						placeholder="Password"
						name="password"
						required
						icon={
							isPasswordVisible ? (
								<IconEyeOff onClick={() => setIsPasswordVisible(false)} className="h-5 w-5 cursor-pointer" />
							) : (
								<IconEye onClick={() => setIsPasswordVisible(true)} className="h-5 w-5 cursor-pointer" />
							)
						}
					/>
					{isPasswordPopupVisible && (
						<div
							style={{
								position: 'absolute',
								...popupStyle
							}}
						>
							<PasswordPopup password={registerData.password} />
						</div>
					)}
					<Input
						type={isRepeatPasswordVisible ? 'text' : 'password'}
						value={registerData.password2}
						onChange={handleChange}
						placeholder="Confirm Password"
						name="password2"
						required
						icon={
							isRepeatPasswordVisible ? (
								<IconEyeOff onClick={() => setIsRepeatPasswordVisible(false)} className="h-5 w-5 cursor-pointer" />
							) : (
								<IconEye onClick={() => setIsRepeatPasswordVisible(true)} className="h-5 w-5 cursor-pointer" />
							)
						}
					/>

					{!isLoading ? (
						<Button
							variant="btn-primary-light"
							className="px-12 mt-4"
							type="submit"
							disabled={
								!registerData.username ||
								!registerData.email ||
								!registerData.password ||
								!registerData.password2 ||
								isLoading
							}
						>
							{isLoading ? <span className="loading loading-spinner loading-md"></span> : 'Signup'}
						</Button>
					) : (
						<button className="btn btn-square" disabled>
							<span className="loading loading-spinner"></span>
						</button>
					)}
				</form>
				<GoogleLogin
					text="signup_with"
					onSuccess={handleSignupSuccess}
					onError={() => {
						console.log('Login Failed');
					}}
				/>

				<h6>
					Already have an account?{' '}
					<Link to="/auth/login" className="underline">
						Login
					</Link>
				</h6>
				<h6>
					<Link to="/auth/whitelist" className="underline">
						Please enter your email for the whitelist request.
					</Link>
				</h6>
			</div>
		</div>
	);
}
