import { createApi } from '@reduxjs/toolkit/query/react';
import { IAchievement } from '../../types/achievement.types';
import baseQueryWithReauth from './customBaseQuery';

interface AchievementsResponse {
	achievements: IAchievement[];
	message: string;
}

interface AchievementResponse {
	data: IAchievement;
	message: string;
}

export interface ClaimAchievementRequest {
	achievementId: string;
}

export const achievementsApi = createApi({
	reducerPath: 'achievementsApi',
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getAchievements: builder.query<AchievementsResponse, void>({
			query: () => 'api/achievements/get-achievements'
		}),
		claimAchievement: builder.mutation<AchievementResponse, ClaimAchievementRequest>({
			query: (body) => ({
				url: `api/achievements/claim-achievement`,
				method: 'POST',
				body
			})
		})
	})
});

export const { useGetAchievementsQuery, useClaimAchievementMutation } = achievementsApi;
