import { Helmet } from 'react-helmet';

export default function PrivacyPolicy() {
	return (
		<div>
			<Helmet>
				<title>Life Tracker - Privacy Policy</title>
				<meta name="description" content="Life Tracker's privacy policy." />
			</Helmet>
			PrivacyPolicy
		</div>
	);
}
